import { useUser } from "../Main"
import React, { SetStateAction } from "react";
import * as Notif from '../pages/Notifications';
import { useLocation } from "react-router-dom";
import { getMentions } from "../util/helpers";
import { NewNotification, NotificationType } from "../pages/Notifications";
import MentionAssist from "./MentionAssist";
import { initSubscriptions } from "../util/Subscriptions";
import { text } from "body-parser";
export enum PostType {
    Init,
    Reply
}

type IPostSettings = {
    type:PostType,
    replyTo?:number
}

export const clearTextbox = ()=>{
    const textBox = document.getElementById("posting-box") as HTMLTextAreaElement;
    if (textBox?.value == "Write stuff to your friends here :3" || 
        textBox?.value == "Post your reply here"){
            textBox.value = "";
        }
}

export default function NewPost (props : IPostSettings){
    // creates a new post of type Initial or Reply

    const {username} = useUser();
    const [imgPreview, setImgPreview] = React.useState("");
    const [uploading, setUploading] = React.useState<boolean>(false);
    const [userList, setUserList] = React.useState<string[]>([]);
    const [maskText, setMaskText] = React.useState<boolean>(false);
    const [maskRationale, setMaskRationale] = React.useState<string>(""); 
    // mention assist
    // const [mentionBuffer, setMentionBuffer] = React.useState<string>("");
    const [mentionAssistMode, setMentionAssistMode] = React.useState<boolean>(false);
    // const [mentionAssistIndx, setMentionAssistIndx] = React.useState<number>(0);
    const [lastKey, setLastKey] = React.useState<String>("");
    
    const textBox = React.useRef<HTMLTextAreaElement>(null);
    
    const location = useLocation();
    const uploadFile = React.useRef<HTMLInputElement>(null);
    const MAX_POST_LENGTH = 420;


    const makeNewPost = async (content : string) =>{        

        let postData = {
            text:content, 
            username:username,
            attachedImage: "",
            timestamp: new Date().toISOString(),
            replyTo:0,
            avi:"",
            mask: maskText,
            maskRationale: maskRationale
        };
        
        if(content.length > MAX_POST_LENGTH){

            // check to see if reason post is long is bc of soundcloud embed
            const matchArr = content.match(/<iframe .* src=\"https:\/\/w\.soundcloud\.com.*<\/[^\s]+>/g);

            // Check if too long accounting for len of sc embed
            if((matchArr && ((content.length - 841) > MAX_POST_LENGTH)) || matchArr === null){
                alert(`Post length too long!\n  🥛Max characters: ${MAX_POST_LENGTH}\n  🌊Your post characters: ${content.length}`);
                return;
            }
                        
        }

        if(content == "Write stuff to your friends here :3"){
            return;
        }

        if(props.type == PostType.Reply && !props.replyTo) {
            alert("No replyTo recipient provided!");
            throw new Error("no reply to recipient!");
            return;
        }

        if(props.type == PostType.Reply && props.replyTo && username){
            // making a new reply post
            // const replyToText = document.getElementsByClassName('formatted-content')[0].textContent;
            // const replyToUser = document.getElementsByClassName('poster-name')[0].textContent;
            const usersInThreadRaw = document.getElementsByClassName('poster-name');
            const usersInThread : string[] = []
            if(!usersInThreadRaw) return;
            for (let i = 0; i < usersInThreadRaw.length; i++) {
                usersInThread.push(usersInThreadRaw[i].textContent!);
            }

            // notify root post owner of reply
            const uniqueUsers = usersInThread.filter((value, index, array) => array.indexOf(value) === index); //unique
            Notif.NewNotification(username, 
                                  usersInThread[0], 
                                  location.pathname, 
                                  Notif.cropNotifText(content), 
                                  Notif.NotificationType.Reply)
            postData.replyTo = props.replyTo

            // notify participants of thread of updates
            const rootText : string = document.getElementsByClassName("formatted-content")[0]? document.getElementsByClassName("formatted-content")[0].textContent! : "";
            const mentions = getMentions(postData.text);
            for (let i = 1; i < uniqueUsers.length; i++) {

                if (uniqueUsers[i] === username) continue; // don't notify self
                if (uniqueUsers[i] === usersInThread[0]) continue; // don't notify root
                if (mentions?.includes("@"+uniqueUsers[i])) continue; // don't notify post mentions

                Notif.NewNotification(`${usersInThread[0]};${username}`, 
                                      uniqueUsers[i], 
                                      location.pathname, 
                                      Notif.cropNotifText(rootText), 
                                      Notif.NotificationType.Updates)
            }
        }
        const avi_url = await (await fetch(`/avi-url?id=${username}`)).json()
        postData.avi = avi_url;

        if(imgPreview.length > 0){
            setUploading(true);
            try{
                // console.log(`data: ${imgPreview}`);
                // console.log(`filename: ${username+"-post-"+Date.now()}`);

                const res = await fetch('/upload-post-img', 
                                        {
                                            method:'POST',
                                            body: JSON.stringify({data:imgPreview, 
                                                                filename:username+"-post-"+Date.now(),
                                                                username:username}),
                                            headers: {'Content-Type': 'application/json'},
                                        })

                // console.log(`got res!`);
                const data = await res.json();
                // console.log(`got data!`);
                postData['attachedImage'] = data.imgPath;

            } catch (err){
                alert(`image upload error ${err}`);
            }
        }

        const res = await fetch("/new-post",
                    {
                        method: "POST",
                        headers: { 
                            'Accept': 'application/json',
                            'Content-Type': 'application/json; charset=UTF-8'},
                        body: JSON.stringify(postData)
                    })
        
        if(res.status == 200) {
            const path = location.pathname.includes("thread") ? location.pathname : `/~/u/${postData.username}`
            const { id } = await res.json();
            await postNotif(postData, id)    
            setUploading(false);
            window.location.reload();
        }

    }

    const postNotif = async (postData : any, id : string)=>{
        const mentions = getMentions(postData.text);
        if(mentions==null) return;
        const url = location.pathname.includes("thread") ? `${location.pathname}?highlight=${id}` : `/~/thread/${id}`;
        for (const mention of mentions) {
            // notification to make a new mention
            await NewNotification(postData.username, 
                            mention.substring(1), 
                            url, 
                            postData.text, 
                            NotificationType.Mention);
        }     
    }

    const uploadClick = ()=>{
        uploadFile.current?.click();
    }

    const uploadImage = async (base64encodedImage : string) => {
        setImgPreview(base64encodedImage);
    }

    const onFileChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
        if (e.target.files!.length < 1) return;
        const fileSize = (e.target.files![0] as File).size / (1024*1024);
        if(fileSize > 8){
            alert(`File is over 6MiB! Provided filesize: ${fileSize}MiB`)
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files![0]);

        console.log(reader.result);
        reader.onloadend = () =>{
            if(reader.result) uploadImage(reader.result.toString());
        }
    }

    const mentionAssist = (e : any)=>{
        // !!! TODO
        // view logs on mobile phones to see if e.key is actually "@" for
        // when pressing @. I feel that no!
        // alert(e.target.value)
        if(mentionAssistMode) setLastKey(new String(e.key));
        if(e.key === "@" || e.key === 'At') {
            setMentionAssistMode(true);
        }
    }

    const toggleMentionAssistMode = () =>{
        setMentionAssistMode(mode=>!mode);
    }

    React.useEffect(()=>{
        fetch("/get-user-list")
        .then(res=>res.json())
        .then(data => {
            setUserList(data.map((user : any)=>user.name))
        })
    },[])

    const addString = (payload : string)=>{
        let textSoFar = textBox.current!.value;
        for (let i = 0; i < textSoFar.length; i++) {
            if(textSoFar[(textSoFar.length-1)-i] === "@"){
                textBox.current!.value = textSoFar.slice(0, textSoFar.length-i) + payload
                setMentionAssistMode(false);
                setLastKey('');
                textBox.current!.selectionStart = textBox.current!.selectionEnd = textBox.current!.value.length;
                return;
            }
        }
    }

    const preventDefaultBehaviour = (e : React.KeyboardEvent<HTMLTextAreaElement>) => {
        if(e.key === "Tab" || (e.key === "Enter" && mentionAssistMode)) e.preventDefault();
    }

    return(
        <div>
            {mentionAssistMode && <MentionAssist
                                    usernames={userList} 
                                    lastKey={lastKey}
                                    toggleAssistMode={toggleMentionAssistMode}
                                    bubbleUpString={addString}/>}
            <textarea ref={textBox} 
                      id="posting-box" 
                      defaultValue={props.type===PostType.Init ? "Write stuff to your friends here :3" : "Post your reply here"}
                      onClick={clearTextbox}
                      onKeyDown={e=>preventDefaultBehaviour(e)}
                      onKeyUp={e=>mentionAssist(e)}/>            
            <p/>
            <div className="center-align">
                <img src={imgPreview} className='post-img-preview'/>
            </div>
            <div className="r-align">
                <span className={uploading? "rainbow" :"hide-me"}>uploading to David...</span>
                <TextMaskToggle 
                    maskText={maskText} 
                    setMaskText={setMaskText} 
                    maskRationale={maskRationale}
                    setMaskRationale={setMaskRationale}/>
                <button className="post-button" onClick={uploadClick} disabled={uploading}>📷</button> 
                <button className="post-button" onClick={()=>makeNewPost(textBox.current!.value)} disabled={uploading}>
                    {props.type===PostType.Init ? "Post" : "Reply"}
                </button>
            </div>
            <input type="file"
                   name="avatar" 
                   onChange={onFileChange} 
                   className="hide-me" 
                   ref={uploadFile} 
                   accept="image/png, image/jpeg, image/jpg, image/gif"
            />
        </div>
    )
}

function TextMaskToggle(props: {maskText: boolean, 
                                maskRationale: string,
                                setMaskText : React.Dispatch<SetStateAction<boolean>>
                                setMaskRationale : React.Dispatch<SetStateAction<string>>
                            }){

    return(
                <div style={{display:"inline-block", cursor:"pointer"}}>
                        <input type="checkbox" 
                                id="mask" 
                                onClick={(e)=>{
                                    if(props.maskText === true){
                                        props.setMaskRationale("")
                                    }
                                    props.setMaskText(!props.maskText)}}></input>
                                <label htmlFor="mask">mask text</label>
                        <br/>
                        {props.maskText&&
                        <input 
                            type="text" 
                            placeholder="description (e.g. content warning, spoiler, etc)" size={35} 
                            maxLength={400}
                            onChange={e=>{props.setMaskRationale(e.target.value)}}></input>}
                </div>
    )
}