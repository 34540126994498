import { setUncaughtExceptionCaptureCallback } from 'process';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../Main';
import '../style/style.css';
import { humanFriendlyDate } from '../util/helpers';
import { initSubscriptions } from '../util/Subscriptions';

export enum NotificationType{
    Like,
    Reply,   
    Mention, 
    Follow,  
    Unknown,
    Updates,
    EventUpdate
}

interface INotification {
    actor: string,
    url: string,
    snippet: string,
    time: string,
    type: NotificationType,
    id?:string,
    to_notify?:string,
    aviUrl?:string,
    seen:boolean,
    children?:React.ReactNode
}

interface IActor {
    name:string,
    url: string
}


const getNotificationType = (intype: string | number) : NotificationType => {
    if(intype=="Like" || intype==0) return NotificationType.Like;
    if(intype=="Reply" || intype==1) return NotificationType.Reply;
    if(intype=="Mention" || intype==2) return NotificationType.Mention;
    if(intype=="Follow" || intype==3) return NotificationType.Follow;
    if(intype=="Updates" || intype==5) return NotificationType.Updates;
    if(intype=="EventUpdate" || intype==6) return NotificationType.EventUpdate;

    else return NotificationType.Unknown;
}

const getVerb = (type : NotificationType) : string => {
    switch (type) {
        case NotificationType.Reply:
            return "replied to your post!"
            break;
        case NotificationType.Mention:
            return "mentioned you!"
            break;
        case NotificationType.Follow:
            return "followed you!"
            break;
        case NotificationType.EventUpdate:
            return "New Special Event Notice from David Social!"
            break;
        default:
            return "interacted with you... somehow!"
            break;
    }
}


export const cropNotifText = (original : string ) : string =>{    
    if(original.length < 106) return original;
    return original.substring(0,106)+"...";
}

export const NewNotification = async (actor: string, 
                                      receiver: string, 
                                      url: string, 
                                      snippet: string, 
                                      type: NotificationType
                                      ) => {

    if(actor === receiver) return;
    snippet = cropNotifText(snippet);
    await fetch(`/new-action-notification`,
    {
      method: "post",
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8'},
      body: JSON.stringify({
                            "actor":actor,
                            "url":url,
                            "snippet":snippet,
                            "toNotify": receiver,
                            "type":type.toString()
                        })
    })
}


export default function Notifications() {
    const { username }= useUser();
    const [notifications, setNotifications] = React.useState<INotification[]>([])
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const getNotifications = async () => {
        if(username==null) return;
        const res = await fetch(`/get-notifications-4?id=${username}`)
        let data = await res.json();
        data.sort(function(a : INotification, b : INotification){return Date.parse(b.time) - Date.parse(a.time)})
        
        const uniqueNames = Array.from(new Set<string>(data.map((item: INotification)=>item.actor)))

        const resAvis = await fetch(`/get-avi-map-for-users`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'COntent-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({
                "users":uniqueNames
            })
            })
        const dataAvis = await resAvis.json();
        
        for (let i = 0; i < data.length; i++) {
            data[i].aviUrl = dataAvis[data[i].actor];
        }
        setNotifications(data);
        setLoaded(true)
        // fetch(`/acknowledge-notification-cards?username=${username}`);
    }
    useEffect(()=>{
        username && fetch(`/seen-notifications?id=${username}`);
        getNotifications();
    },
    [username])
    return(
        <div id="main-content">
        <div id='content-panel'>
            <div className='mobile-pad'>

            <h1>Notifications </h1>
            <SeeSubscribed username={username}/>
            <hr></hr>

            <div className='notif-panel'>
            {
                !loaded && <img src="/img/loading-hourglass.gif" style={{display:"block", marginLeft:"auto", marginRight:"auto"}}/>
            }
            {

            // TODO: filter on notification types here to craft custom components
            notifications.map(n=>
                
                {
                                if(getNotificationType(n.type)===NotificationType.Like){
                                    // likes
                                    return(
                                        <Notification 
                                            actor={n.actor} 
                                            url={n.url}
                                            snippet={n.snippet}
                                            type={getNotificationType(n.type)}
                                            time={n.time}
                                            key={n.id}
                                            aviUrl={n.aviUrl}
                                            seen={n.seen}
                                            id={n.id}

                                        >
                                            <TwoLine 
                                                actors={n.actor}
                                                snippet={n.snippet}
                                                time={n.time}/>
                                        </Notification>
                                    )
                                }
                                if(getNotificationType(n.type)===NotificationType.Updates){
                                    // general updates
                                    return(
                                        <Notification 
                                            actor={n.actor} 
                                            url={n.url}
                                            snippet={n.snippet}
                                            type={getNotificationType(n.type)}
                                            time={n.time}
                                            key={n.id}
                                            aviUrl={n.aviUrl}
                                            seen={n.seen}
                                            id={n.id}

                                        >
                                            <ThreeLine 
                                                actors={n.actor}
                                                snippet={n.snippet}
                                                time={n.time}/>
                                        </Notification>
                                    )
                                }
                                else{
                                    // everything else
                                    const verb = getVerb(getNotificationType(n.type))
                                    return(
                                        <Notification 
                                            actor={n.actor} 
                                            url={n.url}
                                            snippet={n.snippet}
                                            type={getNotificationType(n.type)}
                                            time={n.time}
                                            key={n.id}
                                            aviUrl={n.aviUrl}
                                            seen={n.seen}
                                            id={n.id}

                                        >
                                            <OneLine
                                                aviUrl={n.aviUrl}
                                                actor={n.actor}
                                                time={n.time}
                                                verbIndicator={verb}
                                                snippet={n.snippet}
                                                url={n.url}
                                            />
                                        </Notification>)
                                }
                                // else{
                                //     return(
                                //         <Notification 
                                //             actor={n.actor} 
                                //             url={n.url}
                                //             snippet={n.snippet}
                                //             type={getNotificationType(n.type)}
                                //             time={n.time}
                                //             key={n.id}
                                //             aviUrl={n.aviUrl}
                                //             seen={n.seen}
                                //             id={n.id}
                                //         >
                                //             <OneLine
                                //                 aviUrl={n.aviUrl}
                                //                 actor={n.actor}
                                //                 time={n.time}
                                //                 verbIndicator={verb}
                                //                 snippet={n.snippet}
                                //                 url={n.url}
                                //             />
                                //         </Notification>)
                                //         }
                                    }
                                    )
            }
                            </div>

            </div>
        </div>
        </div>
    )
}

// NEW notification script... 

const getActors= async (actors: string, setActors: Dispatch<SetStateAction<IActor[]>>)=>{
    const parsedActorNames = actors.split(";")
    const parsedActors : IActor[] = []
    for (let i = 0; i < parsedActorNames.length; i++) {
        const aviUrlRes = await fetch(`/avi-url?id=${parsedActorNames[i]}`);
        const aviUrlData = await aviUrlRes.json();
        parsedActors.push({name:parsedActorNames[i], url:aviUrlData})
    }
    setActors(parsedActors)
}

function OneLine(props:{aviUrl:string|undefined, 
                      actor:string, 
                      verbIndicator:string, 
                      time:string, 
                      snippet:string, 
                      url:string}) {
    const navigate = useNavigate()

    return(
        <>
                        <img 
                            src={props.aviUrl}
                            className='mini-user-card-img'/>                        
                        &nbsp;

                        <div>
                            <span className="notification-head">
                                <Link to={`/~/u/${props.actor}`}>@{props.actor}</Link>
                                &nbsp;{props.verbIndicator}
                                <span className="post-timestamp"> {humanFriendlyDate(props.time)}</span>

                            </span>
                        <br></br>
                        {
                            (props.snippet.length>0) &&
                            <div className='notif-plain-quote' onClick={()=>navigate(props.url)}>"{props.snippet}"</div>
                        }
                        </div>
                        </>
    )
}

function LikeLine(props:{actors:IActor[]}){

    const [salientLikes, setSalientLikes] = React.useState<string[]>([]);

    React.useEffect(()=>{
        getSalientLikes(props.actors);
    },[props.actors])
    const getOthersLikedText = (toParse: IActor[]) : string =>{
        if(toParse.length <= 1) return ""
        return ` and ${toParse.length - 1} others`
    }

    const getSalientLikes = (toParse: IActor[]) => {
        if(toParse.length === 0) return;
        if(toParse.length === 2) {setSalientLikes([toParse[toParse.length-1].name, toParse[toParse.length-2].name]); return}
        setSalientLikes([toParse[toParse.length-1].name]); 
        return
    }
    
    return(
            <span>
                {
                salientLikes.length == 0 ? <div className='loading-circle-20'/> :
                salientLikes.length == 2 ?
                    <span>
                    <Link to={`/~/u/${salientLikes[0]}`}>@{salientLikes[0]}</Link> and <Link to={`/~/u/${salientLikes[1]}`}>@{salientLikes[1]}</Link> liked your post</span>
                    :
                    salientLikes && <span><Link to={`/~/u/${salientLikes[0]}`}>@{salientLikes[0]}</Link>
                    {getOthersLikedText(props.actors)} liked your post</span>
                }
            </span>
    )
}


function TwoLine(props:{actors:string, snippet:string, time:string}){
    
    const [actors, setActors] = React.useState<IActor[]>([]);

    React.useEffect(()=>{
        getActors(props.actors, setActors)
    },[])

    return(
        <>
                        <div>
                            {actors.map(a=><img src={a.url} className='notif-user-img-cluster'/>)}
                            <br></br>
                            <span className="notification-head">
                                <LikeLine actors={actors}/>
                                <span className="post-timestamp"> {humanFriendlyDate(props.time)}</span>

                            </span>
                        <div  
                                    className="notif-plain-quote">
                            "{props.snippet}"
                        </div>
                        </div>
                    </>
    )
}


function ThreeLine(props:{actors:string, snippet:string, time:string}){
    // actor[0] is the root thread owner, actor[1]+ is ppl participating
    const [actors, setActors] = React.useState<IActor[]>([]);

    React.useEffect(()=>{
        getActors(props.actors, setActors)
    },[])

    return(

                <div>
                    
                    <span className="notification-head">

                        {actors.length > 0 ? <img src={actors[0].url} className='inline-img-16'/> : <div className='loading-circle-20'/> } 
                        A post you are following has updates!
                        
                        <span className="post-timestamp"> {humanFriendlyDate(props.time)}</span>
                    </span>
                <div className='notif-plain-quote'>
                    "{props.snippet}"
                </div>

                <div>
                    <b>+</b> &nbsp;
                        {actors.length > 0 ? actors.map((actor, i)=>{
                            if(i==0) return;
                            return <img src={actor.url} className='inline-img-16'/>
                        }) :
                        <span className='loading-block'/>}
                        
                        
                </div>
                </div>

    )
}


function Notification(props : INotification) {

    const [symbolIndicator, setSymbolIndicator] = React.useState("🤔")
    const [verbIndicator, setVerbIndicator] = React.useState("interacted with you somehow!")
    
    
    useEffect(()=>{
        const customIndicators = typeIndication(props.type);
        setSymbolIndicator(customIndicators.symbol);
        setVerbIndicator(customIndicators.verb);
        if(props.type === NotificationType.Like) fetch(`/interact-with-notif?id=${props.id}`)
    },
    [props.type, props.id])

    const typeIndication = (type : NotificationType) : {symbol: string, verb: string}  => {
        switch (type) {
            case NotificationType.Follow:
                return {symbol:"🙏", verb:"followed you!"}
                break;
            case NotificationType.Like:
                return {symbol: "💖", verb: "liked your post!"}
                break;
            case NotificationType.Mention:
                return {symbol: "💬", verb: "mentioned you!"}
                break;
            case NotificationType.Reply:
                return {symbol: "↩️", verb: "replied to your post!"}
                break;
            case NotificationType.Updates:
                return {symbol: "⭐", verb: "has updates!"}
                break;
            case NotificationType.EventUpdate:
                return {symbol: "🎅", verb: "David Social Event Update!"}
                break;
            default:
                break;
        }
        return {symbol:"🤔", verb: "interacted with you somehow!"}
    }

    return(
        <a className={`notification clickable-card cursor-is-hand ${props.seen ? '' : 'notif-unread'}`}
            style={{color:"black"}}
            onClick={(event)=>{
                // alert((event.target as HTMLElement).tagName);
                if((event.target as HTMLElement).tagName === 'A') return;
                event.preventDefault();
                fetch(`/interact-with-notif?id=${props.id}`).then(
                    ()=>window.location.href=props.url
                )}}>
                    <div className='notif-indicator'>
                    {symbolIndicator}
                    </div>

                    <div className={'mini-user-card notif-info'}>
                        {props.children}
                    </div>
                    </a>
    )
}




function SeeSubscribed(props:{username:string | null}){
    const subDevList = useRef<HTMLDivElement>(null);
    const [hideToggle, setHideToggle] = useState<boolean>(true);
    const [subs, setSubs] = useState<any[]>([]);
    const getDevices = async () => {
        if(!props.username) return;
        const res = await fetch(`/user-devices?username=${props.username}`);
        const data = await res.json();
        setSubs(data.subscribed_devices);
    }

    const removeDevice = async (deviceName : string) =>{

        const res = await fetch(`/remove-device?deviceName=${deviceName}&username=${props.username}`);
        if(res.status === 201) getDevices();
        else{
            alert("error! something got borked in the backend");
        }
    }
    return(
        <div>
            <div style={{textAlign:'center'}}>
                <button onClick={()=>initSubscriptions(props.username)}>get push notifications</button> &nbsp;   
                <button onClick={()=>{getDevices(); setHideToggle(!hideToggle);}}>see subscribed devices</button>
            </div>
            <div ref={subDevList} className={hideToggle ? 'hide-me' : ""}>
                <ul>
                    {subs.map(s => <li key={s.deviceName}>{s.deviceName} <button onClick={()=>removeDevice(s.deviceName)}>remove</button></li>)}
                    {subs.length < 1 && "no devices are subscribed to push notifications! :-("}
                </ul>

            </div>
        </div>
    )
}