import * as Bowser from 'bowser';

const publicVapidKey = "BLfC6V0OQMaKGlY7AmZl0AsjHbiRHUU578Vpk8AgOBgH17se-jIFI0xsOF56p3VYMBgCJcPqip-x6vMqBGaYpnQ";

function urlBase64ToUint8Array(base64String : string) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const objCpy = (obj : any) : string =>{
    let newObj : any = {}
    const keys : string[] = Object.keys(Object.getPrototypeOf(obj));
    keys.forEach((key :  string)=>{
        let val : any = obj[key]
        if(typeof val === "object" && val !== null){
            val = objCpy(val)
        }
        newObj[key] = val;
    })
    return newObj;
}

const purgeWorkers = () =>{
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
         registration.unregister()
       } })
}



const isIOS = (function () {
    const iosQuirkPresent = function () {
        const audio = new Audio();

        audio.volume = 0.5;
        return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
    };

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAppleDevice = navigator.userAgent.includes('Macintosh');
    const isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

    return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();
  
const subscribeMe = async (username: string, deviceName: string)=>{
    // process.env.PUBLIC_URL
    console.log(`registering service worker ---\\`)
    const reg = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/Worker.js`, {scope:"/"});
    console.log(`registration ---\\`)

    console.log(JSON.stringify(objCpy(reg)))

    console.log(`checking active workers ---\\`)
    const rawRegistrations = await navigator.serviceWorker.getRegistrations()
    
    rawRegistrations.forEach(rawReg =>{
        console.log(JSON.stringify(objCpy(rawReg)))
    })
    
    
    console.log(`subscribing to push manager ---\\`)
    const sub = await reg?.pushManager.subscribe({
                                                  userVisibleOnly: true,
                                                  applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
                                                  })
    console.log(reg.pushManager)
    console.log(`subscription ---\\`)
    console.log(JSON.stringify(sub));
    console.log(`POST request`)
    const res = await fetch("/subscribe-to-push-notif", {
        method: "POST",
        body: JSON.stringify({userSubscription: {deviceName:deviceName, subscription:sub}, username: username}),
        headers: {
            "content-type": "application/json"
        }
    });
    if(res.status === 201) alert('success!');
    if(res.status === 500) alert('uh oh, server error');
    
    
    }


const initSubscriptions = async (username : string | null)=>{
    
    if(!("serviceWorker" in navigator)) {alert('⚠️ no service worker found! you may not be on https or are using an outdated or incompatible web browser\n\nCompatable web browsers: Firefox, Opera, Chrome, Edge, Internet Explorer (v10), and Safari');return}
    if(isIOS) alert("I detect you are using iOS. Currently push notifications are not supported :( I won't stop you from trying to register though!")
    const rawRegistrations = await navigator.serviceWorker.getRegistrations()
    if(rawRegistrations.length > 0) {
        alert("Cleaning up previous settings, try clicking again")
        purgeWorkers();
        return;
    }

    const machineName = prompt("Name of the machine you're using? Example: Blackberry")
    if(!machineName){
        alert("no name provided")
        return;
    }

    alert("⚡ Subscribing to push notifications!\nIf notifications are not working, then you most likely have not enabled notifications on this site. Other reasons could be that you're on http instead of https, or using an outdated web browser.")
    const browserInfo = Bowser.getParser(window.navigator.userAgent);
    const deviceString = `${machineName} ${browserInfo.getPlatformType()} ${browserInfo.getBrowserName()}`
    localStorage.setItem("device_name",deviceString);
    
    if(!username) return;
    if("serviceWorker" in navigator){
        subscribeMe(username, deviceString).catch(err => alert(`problem while subscribing: ${err}`));
    }else{
        alert("I can't find any service workers. You may not be using https or a modern web browser.")
    }
}
export {initSubscriptions}