// functional imports ===========================================
import React, { Dispatch, SetStateAction} from 'react';
import { Link, Outlet, useOutletContext, useNavigate} from 'react-router-dom';
import ProfileIcon from './img/icons/DS-profile.png';
import FeedIcon from './img/icons/DS-feed.gif';
import SearchIcon from './img/icons/DS-search.png';
import NewsIcon from './img/icons/DS-news.png';
import NotifIcon from './img/icons/DS-notif.png';
import './style/style.css';
import Hearts from './components/special_headers/ValentinesHearts';
import './style/specialstyle.css';

// seasonal imports ============================================
// import Snow from './components/special_headers/Snow';
// import FireworksOverlay from './components/special_headers/Fireworks';

type UserName = {username : string | null};

let globalUserName = "";
let polling = false;


export default function Main() {

    const [username, setUsername] = React.useState(null);
    const [newNotification, setNewNotification] = React.useState(false);
    const [userList, setUserList] = React.useState<string[]>([]);

    let navigate = useNavigate();
    
    const pollUpdates = React.useCallback(async () =>{
        if(globalUserName.length !== 0){
            const res = await fetch(`/check-notifications?id=${globalUserName}`)
            const data = await res.json() 
            setNewNotification(!data.seen);
            setTimeout(pollUpdates, 10000);
        }
        else{
            setTimeout(pollUpdates, 1000)
        }
    },[])

    React.useEffect(() => {




        fetch("/validate-session")
            .then(res => res.json())
            .then(data => {
                if(!data.success){
                    console.log('validate session unsuccessful :(')
                    console.log(data);
                    return navigate("/")
                }
                setUsername(data.username);
                globalUserName = data.username;
                // const cookie = document.cookie.split(';')[0].split('=')[1]
                !polling && pollUpdates();
                polling = true;
            });
        
 
    }, [navigate, pollUpdates]);


    return(
            <div id="main">
                {/* 
                    snow, fireworks, or other overlays go here
                */}
                {/* <Hearts/> */}
                {/* <FireworksOverlay/> */}
                {/* <Snow/> */}
                <SideBar newNotification={newNotification}
                         setNewNotif={setNewNotification}></SideBar>
                <Outlet context={{username}}/>
            </div>
    )
}


function SideBar(props : {newNotification: boolean, setNewNotif : Dispatch<SetStateAction<boolean>>}){
    return (
        <div id="side-bar">
            <div className='mobile-only mobile-bar-items'>
                <span className='mobile-bottom-icon' onClick={()=>sessionStorage.setItem("lastPostTarget", "")}><Link to="feed" className="side-bar-link"><img alt='feed icon' className='sidebar-icon' src={FeedIcon}/></Link> </span>
                <span className='mobile-bottom-icon'><Link to="profile" className="side-bar-link"><img alt='profile icon' className='sidebar-icon' src={ProfileIcon}/></Link> </span>
                <span className='mobile-bottom-icon'><Link to="search" className="side-bar-link"><img alt='search icon' className='sidebar-icon' src={SearchIcon}/></Link></span>
                <span className='mobile-bottom-icon'><Link to="news" className="side-bar-link"><img alt='news icon' className='sidebar-icon' src={NewsIcon}/></Link>  </span>
                <span className='mobile-bottom-icon'><span className='overlap'>
                    <Link to="notifications" className="side-bar-link" onClick={()=>props.setNewNotif(false)}> 
                    <span className={props.newNotification ? 'notification-indication' : ''}>
                    </span>
                    <span className='bell'><img alt='notifications icon' className='sidebar-icon' src={NotifIcon}/> </span> 
                    </Link>
                </span></span>
            </div>

            <div className='side-bar-item desktop-only' onClick={()=>sessionStorage.setItem("lastPostTarget", "")}><Link to="feed" className="side-bar-link"><img alt='feed icon' className='sidebar-icon' src={FeedIcon}/> Feed</Link></div>
            <div className='side-bar-item desktop-only'><Link to="profile" className="side-bar-link"><img alt='profile icon' className='sidebar-icon' src={ProfileIcon}/> Profile</Link></div>
            <div className='side-bar-item desktop-only'><Link to="search" className="side-bar-link"><img alt='search icon' className='sidebar-icon' src={SearchIcon}/> Search</Link></div>
            <div className='side-bar-item desktop-only'><Link to="news" className="side-bar-link"><img alt='news icon' className='sidebar-icon' src={NewsIcon}/>     News</Link></div>
            <div className='side-bar-item desktop-only' onClick={()=>props.setNewNotif(false)}>
                <span className='overlap'>
                    <span className={props.newNotification ? 'notification-indication' : ''}></span>
                    <span className='bell'><img alt='notifications icon' className='sidebar-icon' src={NotifIcon}/> </span>
                </span>
                <Link to="notifications" className="side-bar-link">Notif.</Link></div>
        </div>
    )
}

function useUser(){
    return useOutletContext<UserName>();
}






export {globalUserName, useUser, SideBar}
