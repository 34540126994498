import '../style/style.css';
import Connections from './Connections';
import { useParams } from 'react-router-dom';

export default function Bootlickers() {
    const params = useParams();

    return(
        <div id="main-content">
        <div id="content-panel">
            <h1>{params.id}'s Bootlickers</h1>
            <Connections connectionType="bootlickers" />
        </div>
        </div>
    )
}

