import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import '../style/style.css';
import Marquee from "react-fast-marquee";
import { useUser } from '../Main';
import Post from '../components/Post';
import EditFacts from '../components/EditFacts';
import NewPost, {PostType} from '../components/NewPost';
import { IPost } from '../components/Post';
import { Link } from 'react-router-dom';
import { facts } from '../common/Profile.common';


let localFacts = facts;
// let facts = {
//     "Favourite food" : "",
//     "Favourite genre of music" : "",
//     "Favourite show" : "",
//     "Mp3 player of choice" : "",
//     "Wallpaper of choice" : "",
//     "First kiss": "",
//     "Subs or dubs" : "",
//     "Current crush" : "",
//     "Hyperfixations" : "",
//     "Astrological identity" : "",
//     "Other interests" : ""
// }

// let bio = "I'm pretty random! I like eating cheese XD jk"

// let profile = {
//     "status":"ass",
//     "about":"I'm pretty random! I like eating cheese XD jk",
//     facts:facts
// }

interface IBioContent {
    bioContent : string,
    setEdit : Dispatch<SetStateAction<boolean>>
    callback? : (txt: string) => void
    // updateCallback? : Dispatch<SetStateAction<string>>
}



export default function PersonalProfile() {
    const {username} = useUser();

    const [showEditF, setShowEditF] = React.useState(false);
    const [showEditBio, setShowEditBio] = React.useState(false);
    const [showEditStatus, setShowEditStatus] = React.useState(false);

    const [bioText, setBioText] = React.useState("");
    const [currentStatus, setCurrentStatus] = React.useState("");

    const uploadFile = useRef<HTMLInputElement>(null);

    const [aviSrc, setAviSrc] = React.useState("/img/loading-hourglass.gif");
    const [fallback, setFallback] = React.useState(false);

    const [posts, setPosts] = React.useState<any[]>([]);

    const [bootlickerCount, setBootlickerCount] = React.useState(0);
    const [followingCount, setFollowingCount] = React.useState(0);

    useEffect(()=>{
        const fetchProfileData = async () => {
            const res = await fetch(`/profile?username=${username}`);
            const data = await res.json();
            setBioText((!data.bio || data.bio == "null") ? "" : data.bio);
            setCurrentStatus(data.status? data.status : "");
            data.bootlickers ? setBootlickerCount(data.bootlickers.length) : setBootlickerCount(0)
            data.following ? setFollowingCount(data.following.length) : setFollowingCount(0)
            localFacts = Object.keys(data.facts).length > 0 ? data.facts : localFacts;

            setAviSrc(data.avi ? data.avi : "/avis/default-avi.jpg");
        }

        const fetchUserPosts = async () => {
            const res = await fetch(`/userPosts?username=${username}`);
            const data = await res.json();
            setPosts(data);
        }

        if(username){
            setAviSrc(`/avis/${username}-avi.jpg`);
            
            fetchProfileData()
            .catch(console.error);
            
            fetchUserPosts()
            .catch(console.error);
            
        }

    }, [username])

    const updateFactsObj = ()=>{
    
        let customFacts : Element[] = Array.from(document.getElementsByClassName("custom-fact-field"));
        for (let i = 0; i < customFacts.length; i++) {
            localFacts[customFacts[i].id as keyof typeof facts] = (customFacts[i] as HTMLInputElement).value as string;
        }
    
        fetch("/update-facts",
        {
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({'facts':JSON.stringify(localFacts), username:username})
        })
    }

    // const reloadSrc = (e : any) => {
    //     if(fallback){
    //         e.target.src = "/avis/default-avi.jpg";
    //     }
    //     else{
    //         e.target.src = aviSrc;
    //         setFallback(true);
    //     }
    // }

    const uploadClick = ()=>{
        alert("NOTE: image must be smaller than 6MiB! Square photos work best :)");
        uploadFile.current?.click();
    }

    const uploadImage = async (base64encodedImage : string | ArrayBuffer | null) => {
        try{
            await fetch('/upload-avi', {
                method:'POST',
                body: JSON.stringify({data:base64encodedImage, 
                                      filename:username+"-avi",
                                      username:username}),
                headers: {'Content-Type': 'application/json'},
            })
        } catch (err){
            alert(err);
        }
    }

    const onFileChange = (e : React.ChangeEvent<HTMLInputElement>)=>{
        if (e.target.files!.length < 1) return;
        const fileSize = (e.target.files![0] as File).size / (1024*1024);
        if(fileSize > 8){
            alert(`File is over 6MiB! Provided filesize: ${fileSize}MiB`)
            return;
        }

        const reader = new FileReader();
        reader.onload = ()=>{
            let out : HTMLImageElement = document.getElementById("profile-avi") as HTMLImageElement;
            out.src = reader.result as string;
        }

        reader.readAsDataURL(e.target.files![0]);
        reader.onloadend = () =>{
            uploadImage(reader.result);
        }
    }

    const updateBioText = (txt : string) : void =>{
        console.log(`update bio text called with text: ${txt}`);
        fetch("/update-bio",
        {
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({"bio":txt, "username":username})
        })
        setBioText(txt);
    }

    const updateStatus = (txt : string) : void =>{
        console.log(`update status called with status: ${txt}`);
        setCurrentStatus(txt);
        fetch("/update-status",
        {
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({"status":txt, "username":username})
        })
    }


    return(
        <div id="main-content">
        <div id="content-panel">
            <div className='mobile-pad'>
            <div id="profile-header">
                <img className="editable-image" 
                     src={aviSrc} 
                     id="profile-avi" 
                     onClick={uploadClick}
                     alt={`an editable profile picture for ${username}`} />
                <div>

                {/* USERNAME AND STATUS ========================== */}

                <h1>{username}</h1>
                    {showEditStatus ?
                    <EditStatus  bioContent=""
                                 setEdit={setShowEditStatus}
                                 callback={updateStatus}/>
                    :
                    <div className='editable-text' onClick={()=>setShowEditStatus(true)}>
                        <Marquee gradient={false}>{currentStatus.length < 2 ? "[set your status]" : currentStatus}</Marquee>
                    </div>
                    }

                <p></p>
                
                <div className='follower-panel'>
                    {/* Bootlickers: <a href="">0</a> */}
                    bootlickers:
                        <Link to={`/~/bootlickers/${username}`}>{bootlickerCount}</Link> 
                        <br/>
                        bootlicking: 
                        <Link to={`/~/u-following/${username}`}>{followingCount}</Link>
                    </div>
                </div>
                
            </div>

            <div>
            
            <input type="file"
                   name="avatar" 
                   onChange={onFileChange} 
                   className="hide-me" 
                   ref={uploadFile} 
                   accept="image/png, image/jpeg, image/jpg, image/gif"/>
            </div>
            <hr/>
            <div>

                <h3>About Me</h3>

                {/* bio =============================================================================*/}
                {
                    showEditBio ?
                    <EditBio bioContent={document.getElementById("bio")?.innerHTML || ""}
                            setEdit={setShowEditBio}
                            callback={updateBioText}/>
                    :
                    <div id="bio" className='editable-text' onClick={()=>setShowEditBio(true)}>
                            {bioText === "" ?
                            "[Edit your bio here]" :
                            bioText
                            }
                    </div>

                }
            </div>
            <div>
                {/* facts about me */}
                { showEditF ? <EditFacts showEdit={showEditF} 
                                setEdit={setShowEditF} 
                                facts={localFacts} 
                                updateCallback={updateFactsObj}/> 
                :
                
                <div className='editable-text' onClick={e=>setShowEditF(true)}>
                    <ul id="facts">
                        {
                            localFacts ? 
                                Object.entries(localFacts)
                                .filter(([_,v]) => v != "")
                                .map(e => <li key={e[0]}><span className="profile-fact">{e[0]}</span> {e[1].toString()}</li>)
                                :
                                ""
                        }
                    </ul>
                    {
                        
                        (Object.values(localFacts).filter((v)=>v!="").length < 1) &&  "[edit facts]"
                    }
                </div>
                }
            </div>

            


            <h3>Post an update</h3>
            <NewPost type={PostType.Init}/>
            </div>
            <div id="profile-feed">
            {posts.map((p)=><Post 
                                avi={p.avi}
                                username={p.username}
                                content={p.content}
                                attachImage={p.attached_image}
                                likes={p.likes}
                                timestamp={p.timestamp}
                                id={p.id} 
                                key={p.id}
                                ncomments={p.ncomments}
                                reply_to={p.reply_to}
                                mask={p.mask}
                                mask_desc={p.mask_desc}
                                />)}
            </div>

        </div>
        </div>
    )
}


function EditBio(props : IBioContent ){
    const [BioContent, SetBioContent] = React.useState("");
    return(
        <div>
            <textarea id="posting-box" defaultValue={props.bioContent}>
            </textarea>
            <button onClick={()=>{props.setEdit(false); 
            props.callback!((document.getElementById("posting-box") as HTMLTextAreaElement)?.value || "null")}}>save</button>
        </div>
    )   
}


function EditStatus(props : IBioContent){
    
    const statusTypes = [
                         "🎵 listening:",
                         "🎮 playing:",
                         "🍽️ eating:",
                         "🏃 currently doing:",
                         "💭 thinking about:",
                         "🐥 feeling:"
                        ]


    return(
        <div>

            <select id="status-selector">
                {statusTypes.map(status => <option key={status} value={status}>{status}</option>)}
                <option value="">❓ custom status:</option>
            </select>
            <input type="text" id="status-text" defaultValue={props.bioContent}></input> 
            <button onClick={()=>{props.callback!((document.getElementById("status-selector") as HTMLSelectElement).value + " " +(document.getElementById("status-text") as HTMLInputElement).value) ; props.setEdit(false)}}>update</button>
        </div>
    )
}