import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/style.css';
import { generateSlug } from 'random-word-slugs';
export default function AddUser() {
    const [userList, setUserList] = useState<any[]>([]);
    const auth = useRef<HTMLInputElement>(null);
    const name = useRef<HTMLInputElement>(null);
    const pw = useRef<HTMLInputElement>(null);
    const output = useRef<HTMLInputElement>(null);

    const genPw = ()=>{
        pw.current!.value = generateSlug(2,{format:"camel"} );
    }
    useEffect(()=>{
        genPw();
    },[])
    const addUser = async () =>{
        const trimName = name.current?.value.trim();
        const submit = window.confirm(`add ${trimName}?`);
        if(!submit) return;
        output.current!.innerHTML += `<p>submitting ${trimName}...</p>`        
        const aviRes = await fetch(`/add-user`, {
            method: "post",
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({"auth":auth.current?.value,
                                  "username":trimName,
                                  "password":pw.current?.value})
        })
        if(aviRes.status === 200){
            output.current!.innerHTML += `<p><span style='color:green'>Success!</span>
                                          <br>Username: ${name.current?.value}
                                          <br>Password: ${pw.current?.value}</p>`
            return;
        }
        if(aviRes.status === 403){
            output.current!.innerHTML += `<p><span style='color:red'>auth error</span> incorrect configuration</p>`
        }
        if(aviRes.status === 500){
            output.current!.innerHTML += `<p><span style='color:red'>server error</span> something got borked</p>`
        }
    }
    return(
        <div style={{margin:"50px"}}>
           <h1>add user panel</h1>
           <p>
            Auth <input type="password" ref={auth}></input>
           </p>
           <p>
            Name <input type="text" ref={name}></input>
           </p>
           <p>
            Password <input type="text" ref={pw}></input><button onClick={genPw}>generate</button>
           </p>
            <p>
                <button onClick={addUser}>submit</button>
            </p>
           <b>output</b>: 
           <div id='output' ref={output}></div>
        </div>
    )
}
