import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import reportWebVitals from './util/reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Login from './pages/Login';
import Main from './Main';
import Feed from './pages/Feed';
import Profile from './pages/Profile';
import PersonalProfile from './pages/PersonalProfile';
import Search from './pages/Search';
import ReplyThread from './pages/ReplyThread';
import Bootlickers from './pages/Bootlickers';
import Following from './pages/Following';
import News from './pages/News';
import Notifications from './pages/Notifications';
import Likes from './pages/Likes';
import PostHighlight from './pages/PostHighlight';
import AddUser from './pages/AddUser';
import UpdateTicker from './components/UpdateTicker';
import PublicUpdateTicker from './pages/PublicUpdateTicker';
import Chatroom from './pages/Chatroom';
import Bulletin from './pages/Bulletin';
import APIDocs from './pages/APIDocs';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="api-docs" element={<APIDocs/>}/>
        <Route path="~" element={<Main/>}>
          <Route path="profile" element={<PersonalProfile/>}/>
          <Route path="feed" element={<Feed/>}/>
          <Route path="search" element={<Search/>}/>
          <Route path="news" element={<News/>}/>
          <Route path="notifications" element={<Notifications/>}/>
          <Route path="chat" element={<Chatroom/>}/>

          <Route path="u/:id" element={<Profile/>}/>
          <Route path="thread/:id" element={<ReplyThread/>}/>
          <Route path="bootlickers/:id" element={<Bootlickers/>}/>
          <Route path="u-following/:id" element={<Following/>}/>
          <Route path="likes/:id" element={<Likes/>}/>
          <Route path="post/:id" element={<PostHighlight/>}/>
          <Route path="bulletin/:msg" element={<Bulletin/>}/>
          <Route path="public-update-ticker" element={<PublicUpdateTicker/>}/>
          {/* <Route path="update-ticker" element={<UpdateTicker/>}/> */}

        </Route>
        <Route path="add-user" element={<AddUser/>}/>
      </Routes>

    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
