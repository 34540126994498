import { useEffect, useState } from 'react';
import Post from '../components/Post';
import '../style/style.css';
import { Link } from 'react-router-dom';
import CLImg from '../img/promo/ds-cli-monitor.png';
export default function News() {
    const [proTip, setProtip] = useState<any>(null);
// 
    // useEffect(()=>{
    //     fetch(`/postData?id=${368}`)
    //     .then(res => res.json())
    //     .then(data => setProtip(data))
    // },[])
    return(
        <div id="main-content">
        <div id='content-panel'>
        <div className='mobile-pad'>
                


            <h1>David News + Info 🗣️</h1>
            <hr></hr>

            <h2>New Joiner?</h2>
            Hi welcome to David. David is the algorithm. David uses what's called "organic intelligence" or "David Intelligence™" (DI) to promote manually suggest people to follow. DI is like AI, except it runs entirely on David's brain and is completely manual.
            {/* To find out more about DI, <a onClick={()=>alert('DI vs AI ----------\nDI and AI share many qualities: both use statistical reasoning about the world, both integrate multiple sources of information to solve problems. However, DI is executed by an agent who has sentience and can feel emotions, pain, and qualia (the feeling of what is it like), whereas AI does not have these traits. Additionally, DI is executed on organic wetware architecture that was iteratively developed over eons using an evolutionary algorithm, whereas modern AI is executed on modular silicone-based Von Neumann architecture dating back to the 1940s. Modern AI learns by tuning parameters based off maximizing or minimizing a loss function in order to solve a specific problem, leading to their performance to be "domain specific" or in other words restricted to solving certain problems. In contrast, there is no identifiable general loss function associated with DI. This distinction arguably makes DI a "domain general" reasoning agent, able to integrate multimodal information across disparate domains.')}>click here</a>. */}
            <br/>
            On David, notifications are opt-in. Also, you can install David as an app! Check out pro-tips for more information about this.
            
            <p/>
            

            <h2>👍 Pro Tips:</h2>
            <h3>How to access David Social</h3>
            David Social can be accessed at davidsocial.com, www.davidsocial.com, and wwd.davidsocial.com (wwd stands for World Wide David).
            <p></p>
            David Social is a *~Progressive Web App~* which means that it is a website that can be installed as an app, and has all the features that a native app would normally have (e.g. push notifications).
            <h3>📲 How to install David as an "app"</h3>
            <b>Android</b> - Chrome or Firefox
            Select "install app" from the main menu
            <p/>
            <div style={{ textAlign:"center"}}>
                <img src='https://res.cloudinary.com/dddd4psei/image/upload/v1671292718/post-attachments/David-post-1671292716206.jpg'
                style={{maxWidth:"300px", width:"100%"}}/>
            </div>
            <p/>
            <b>iOS</b> - Safari, Chrome, or Firefox
            Select "Add to Homescreen"
            <div style={{ textAlign:"center"}}>
                <img src='https://www.groovypost.com/wp-content/uploads/2019/12/1-pin-website-to-iPhone.png'
                style={{maxWidth:"400px", width:"100%"}}/>
            </div>
            {/* {proTip && <Post avi={proTip.avi} 
                                username={proTip.username} 
                                content={proTip.content} 
                                attachImage={proTip.attached_image} 
                                likes={proTip.likes} 
                                timestamp={proTip.timestamp}
                                id={proTip.id}
                                key={proTip.id}
                                ncomments={proTip.ncomments}
                                reply_to={proTip.reply_to}/>} */}
            <h3>🔔 How to get push notifications</h3>
            Push notification are totally opt in and are able to be recieved on both desktop and mobile. Enabling push notifications is as easy as 1, 2!
            <ol>
                <li>Go to notifications tab</li>
                <li>Click the "get push notifications" button, and follow the prompts</li>
            </ol>
            
            <hr></hr>

            <h1>Q: I really want to use David Social on the command line. Is there a CLI?</h1>
            <b>A:</b> I'm so glad you asked! Yes there is! It was developed by David Social's own <Link to="/~/u/eatkin">@eatkin</Link>.
            <div style={{textAlign:"center"}}>
                <img src={CLImg} style={{width:"80%"}}/>
                <h3><a href="https://github.com/Eatkin/david-social-cli#david-social-command-line-interface">Github Link</a></h3>
            </div>

            Note that it has only been tested on linux (Ubuntu WSL 20 LTS)
            <hr></hr>


            <div style={{textAlign:"center"}}>
                <h1>10,000 POSTS CELEBRATION</h1>
                <h3><i>"We did it"</i></h3>
                <iframe src="https://drive.google.com/file/d/142msYfQ20uFEOHXAAKt3-OtnApeZSeft/preview" width="100%" style={{minHeight:"400px"}} allow="fullscreen" ></iframe>
            </div>
            <hr/>



            <h2>✨ Features in progress:</h2>
            <ul>
                <li>Badges</li>
                <li>More embedding options</li>
                <li>Native mobile support</li>
                <li>Unlike posts</li>
                <li>Dark mode??</li>
                <li><del>notifications</del>[✅ Dec. 8th 2022]</li> 
                <li><del>Get notified on @ mention</del>[✅ Dec. 12th 2022]</li>
                <li><del>attach images to posts</del>[✅ Dec 13th 2022]</li>
                <li><del>The ability to delete posts</del>[✅ Dec 13th 2022]</li>
                <li><del>A max post length</del>[✅ Dec 13th 2022]</li>
                <li><del>Get notifications when followed</del> [✅ Dec 15th 2022]</li>
                <li><del>Adjustments to feed so that it is clear who you are following</del> [✅Dec 16th 2022]</li>
                <li><del>Make notification links work better</del> [✅ Dec 16th 2022]</li>
                <li><del>Search page</del> [✅ Dec 18th 2022]</li>
                <li><del>@ mention button</del>[✅ Dec 26th 2022]</li>
                <li><del>bugfix for long text trailing off posts</del>[✅ Dec 26th 2022]</li>
                <li><del>Some indicator that a user is new (log join dates?)</del>[✅ Dec 27th 2022]</li>
                <li><del>PUSH NOTIFICATIONS</del>[✅ Dec 28th 2022]</li>
                <li><del>multiple image format support</del>[✅ Jan 3rd 2023]</li>
                <li><del>case insensitive mentions</del>[✅ Jan 5th 2023]</li>
                <li><del>@ mention suggestions</del>[✅ Jan 5th 2023]</li>
                <li><del>Fake viewcounts</del>[✅ Jan 5th 2023]</li>
                <li><del>global feed</del>[✅ Jan 10th 2023]</li>
                <li><del>Youtube embeds</del>[✅ Jan 15th 2023]</li>
                <li><del>Youtube embeds</del>[✅ Jan 15th 2023]</li>
                <li><del>Hyperlinks</del>[✅ Jan 15th 2023]</li>
                <li><del>e n h a n c e d notifications</del>[✅ March 20th 2023]</li> 
                <li><del>pet the cat</del>[✅ Aug 1st 2023]</li> 
                <li><del>Chat room</del> [✅ Dec 31 2023]</li>
                <li><del>mask text</del></li>

            </ul>
        </div>
        </div>
        </div>
    )
}
