import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import '../style/login.css';

const validateCredentials = async (formData : any) =>{
  formData.preventDefault();
  fetch(`/login`,
  {
    method: "post",
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=UTF-8'},
    body: JSON.stringify({"username":formData.target[0].value,
                          "password":formData.target[1].value})
  }).then(res => {

    if(res.statusText === "WRONG_USERNAME"){
      alert("Sorry! Username is incorrect");
    }
    if(res.statusText === "WRONG_PASSWORD"){
      alert("Oh no! Wrong password!");
    }
    if(res.status === 200){
      alert("successfully logged in :)");
      window.location.href = "/~/feed";
    }
  });
}

function Login() {
  let navigate = useNavigate();

  React.useEffect(()=>{
    fetch("/validate-session")
    .then(res => res.json())
    .then(data => {
        if(!data.success){
            console.log('validate session unsuccessful :(')
            console.log(data);
            return navigate("/")
        }else{
          window.confirm(`welcome back to David Social! Login as ${data.username}?`) && navigate("/~/feed")
        }
    });

    fetch("/version")
    .then(res=>res.json())
    .then(data=> {
      window.localStorage.setItem("VERSION", data.version);
    })

  },[])

  return(
    <div id="welcomepage">
      <h1>Welcome to David</h1>
      <p>
        "<i>The most centralized social media platform on earth.</i>" - David
      </p>

      <p>
        To <b>sign up</b> for David social, you must contact David.
      </p>
      <p>
        David ultimately controls all aspects of David, including your username, password, etc.
      </p>
      <hr></hr>
      <h2> Login </h2>

      <form onSubmit={validateCredentials}>
        <label>
          <p>Username</p>
          <input type="text" />
        </label>
        <label>
          <p>Pw</p>
          <input type="password" />
        </label>
        <div>
          <button type="submit">login</button>
        </div>
      </form>
      <p>
        <i>Forgot your password?</i> Contact David.
      </p>

    <hr></hr>
    <h2>About David</h2>
    David is basically a better version of <del>Twitter</del> X run and operated by David. <br/>
    <b>Q</b>: Why is David a better version of <del>Twitter</del> X? <br/>
    <b>A</b>: Mainly because it is run by David. If you would like more info, David would be happy to elaborate.
    <p>
      <i>"David is like if Craigslist was <del>Twitter</del> X"</i> -- David
    </p>
    <p>
      <ol>
        <li>David</li>
      </ol>
    </p>
    I hope you enjoy David! Sincerly, David
    <div>
    </div>
    </div>

  )
}

export default Login;
