import React, { useEffect, useState } from 'react';
import '../style/style.css';
import  Post, {DeletePost, IPost}  from '../components/Post';
import { Link, Navigate, Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

const getThreadData = async (id: number) => {
    const res = await fetch(`/postData?id=${id}`);
    const data = await res.json();
    return data;
}

export default function PostHighlight() {
    const params = useParams();
    const [spotlightPost, setSpotlightPost] = useState<any>(null);
    const [replyToThread, setReplyToThread] = useState<any>(null);
    const navigate = useNavigate()


    useEffect(()=>{
        const getPostData = async () => {
            if(!params.id) return;
            const data = await getThreadData(parseInt(params.id));
            setSpotlightPost(data);
            if(data.reply_to){
                const replyData = await getThreadData(parseInt(data.reply_to));
                setReplyToThread(replyData);
            }
        }
        getPostData()

    },[params])
    return(
        <div id="main-content">
        <div id="content-panel" >
            <h1>Post #{params.id}</h1>
            {
                replyToThread?
                <div>
                    <Link to={`/~/u/${replyToThread.username}`}>@{replyToThread.username}</Link>
                    <div className='notif-quote' onClick={()=>navigate(`/~/thread/${replyToThread.id}`)}>{replyToThread.content}</div>
                    <div className='center-align'>↓</div>
                    <div className='center-align'>...</div>
                    <div className='center-align'>↓</div>
                </div>
                :
                ""
            }

            {spotlightPost ?
                <Post
                id={spotlightPost.id}
                avi={spotlightPost.avi}
                username={spotlightPost.username}
                content={spotlightPost.content}
                attachImage={spotlightPost.attached_image}
                likes={spotlightPost.likes}
                timestamp={spotlightPost.timestamp}
                ncomments={spotlightPost.ncomments}
                reply_to={spotlightPost.reply_to}
                mask={spotlightPost.mask}
                mask_desc={spotlightPost.mask_desc}
                />

            :
            ""
            }
            
        </div>
        </div>
    )
}
