let facts = {
    "Favourite food" : "",
    "Favourite genre of music" : "",
    "Favourite show" : "",
    "Mp3 player of choice" : "",
    "Wallpaper of choice" : "",
    "First kiss": "",
    "Subs or dubs" : "",
    "Current crush" : "",
    "Hyperfixations" : "",
    "Astrological identity" : "",
    "Other interests" : ""
}

export {facts}
