import React, { useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { globalUserName, useUser } from '../Main';
import { clearTextbox } from './NewPost';
import { humanFriendlyDate, randn_bm} from '../util/helpers';
import { textPreprocessor } from '../util/helpers';
import * as Notif from '../pages/Notifications';
import '../style/style.css';
import { randomBytes } from 'crypto';
import { useOnScreen } from '../util/CustomHooks';

export interface IPost {
    avi : string;
    username : string;
    content: string;
    attachImage: string;
    likes: number;
    timestamp: string;
    id : number;
    reply_to : number;
    ncomments : number;
    reply? : boolean;
    disableReply? : boolean;
    highlight?: boolean;
    david_selection?:boolean;
    mask: boolean;
    mask_desc: string;
}


export default function Post(props : IPost){
    const [count, setCount] = React.useState(props.likes);
    const [imgSrc, setImgSrc] = React.useState("/img/loading-hourglass.gif");
    const [fallback, setFallback] = React.useState(false);
    const [alreadyLiked, setAlreadyLiked] = React.useState(false);
    const [activeImageViewer, setActiveImageViewer] = React.useState(false);
    const postBody = React.useRef<HTMLDivElement>(null);
    const onScreen = useOnScreen(postBody);
    const [show, setShow] = React.useState<boolean>(!props.mask) 

    const checkAlreadyLiked = async () => {
        const res = await fetch(`/check-already-liked?postId=${props.id}&username=${globalUserName}`);
        const data = await res.json();
        setAlreadyLiked(data.alreadyLiked);
    }

    React.useEffect(()=>{
        if(props.username){
            setImgSrc(`/avis/${props.username}-avi.jpg`);
        }
        if(globalUserName){
            checkAlreadyLiked()
        }

    },[props.username, globalUserName])

    React.useEffect(()=>{
        
        onScreen && (props.reply_to === 0) && sessionStorage.setItem("lastPostTarget", props.id.toString());
    },[onScreen])

    const reloadSrc = (e : any) => {
        if(fallback){
            e.target.src = "/avis/default-avi.jpg";
        }
        else{
            e.target.src = imgSrc
            setFallback(true);
        }
    }
    
    const likePost = async ()=>{

        if(!alreadyLiked){

            setCount(count + 1);
            setAlreadyLiked(true);
            await fetch("/like-post",
            {
                method: "POST",
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8'},
                body: JSON.stringify({username:globalUserName, postId:props.id})
            })
            
            const gotoUrl = `/~/thread/${(props.reply_to == 0)? props.id : props.reply_to}?highlight=${props.id}`
            
            Notif.NewNotification(globalUserName, 
                                  props.username, 
                                  gotoUrl, 
                                  `${Notif.cropNotifText(props.content)}`, 
                                  Notif.NotificationType.Like)
        }
        else{
            alert("Can't unlike posts yet! WIP...")
        }
    }

    const davidSelectionGeneration = () : string => {
        const phrases = [
            "David Selection",
            "DI recommendation",
            "Served by Organic Intelligence",
            "Selected using David Intelligence",
            "David Selection",
        ]

        return phrases[Math.floor(Math.random()*phrases.length)]
    }

    const fakeViews = () : string =>{
        const n = Math.ceil(randn_bm(0,1,4)*10_000_000)
        if (n < 1000) return n.toString()
        if (n < 1_000_000) return (n/1000).toFixed(1).toString()+"k"
        return (n/1_000_000).toFixed(1).toString()+"m"
    }

    const toggleShow = () : void =>{
        setShow(!show);
    }


    return(
        <>
        {props.david_selection && <span className='post-timestamp'> *:･ﾟ✧*:･ﾟ✧ {davidSelectionGeneration()} </span>}
        <div className={[props.reply? "reply" : "post", props.highlight && "post-highlight"].join(" ")} 
             key={props.id} 
             id={props.id.toString()}
             ref={postBody}>     
            <div>
                <img className="post-avi" 
                     src={props.avi ? props.avi : "/avis/default-avi.jpg"} 
                     onError={reloadSrc} 
                     alt={`profile of ${props.username}`}/>
                     
            </div> 
            <div className="post-r-col">
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <div>
                        
                        <Link to={`../u/${props.username}`}><span className="poster-name">{props.username}</span></Link> 
                        &nbsp;
                        {/* <div style={JSON.parse(`{ "display":"inline-block", 
                                                  "padding":"0.5em", 
                                                  "backgroundColor":"#526ff2", 
                                                  "borderRadius":"8px",
                                                  "color":"white",
                                                  "fontSize":"12px",
                                                  "fontWeight":"bold"}`)}>
                            Creator of DS
                        </div> */}
                        {/* <div style={JSON.parse(`{"dipsplay":"inline-block", 
                                                 "padding":"0.5em", 
                                                 "backgroundColor" :"#526ff2", 
                                                 "color":"white",
                                                 "borderRadius":"12px"}`)}>
                                                        hi
                            </div> */}
                        {/* "backgroundImage":"linear-gradient(45deg, #482aef, #e3ced4)", */}                        
                    </div>
                    <div><span className="post-timestamp"> {humanFriendlyDate(props.timestamp)}</span></div>
                </div>
                
                {/* <span className="post-timestamp">(not bootlicking)</span> */}
                
                <p/>

                    {
                        props.mask?
                      <MaskText text={props.content} 
                                mask_desc={props.mask_desc}
                                toggleShowCallback={toggleShow}
                                show={show}/>
                                : 
                                textPreprocessor(props.content, props.id)}
                {
                    !show ? 
                    props.attachImage && 
                    <div style={{textAlign:"center"}}>
                        <img className='post-img'
                        src="/img/censor-smol.png"  
                        alt="censored"/>
                    </div>
                    :
                    <img className="post-img" 
                        src={props.attachImage}
                        onClick={()=>setActiveImageViewer(true)}
                        />
                }
                <div className="r-align">
                    {/* <hr className='hr-divider' /> */}
                    <div className='post-meta-wrapper'>
                    <div className="post-meta">Fake Views: {fakeViews()}</div>
                    
                    {/* likes */}
                    {count > 0 && <div className="post-meta">Likes: <Link to={`/~/likes/${props.id}`}>{count}</Link></div>}

                    {/* replies */}
                    {props.reply_to === 0 && <div className="post-meta">  Replies: <Link to={`/~/thread/${props.id}`}>{props.ncomments} </Link></div>}
                    </div>


                    {/* <3 button */}
                    <button onClick={likePost} className={`post-button ${alreadyLiked && 'already-liked'}`}>&lt;3</button> 
                    {/* reply button */}
                    {
                        (props.disableReply || props.reply_to > 0 ) ? "" : 
                            <ReplyButton id={props.id} ncomments={props.ncomments}/>
                    }
                    
                    
                    {/* @ mention */}
                    {props.reply_to > 0 ? <AddMention handle={props.username}/> : ""}
                    
                    <DeletePost id={props.id} postUsername={props.username}/>
                    <ImgViewer imgUrl={props.attachImage} 
                               active={activeImageViewer}
                               callbackDisable={setActiveImageViewer}/>
                </div>
            </div>
        </div>
        </>

    )
}

export function ReplyButton(props:{id:number, ncomments:number}){
    const navigate = useNavigate();

    const replyToPost = (postId : number) => {     
        navigate(`/~/thread/${postId}`);
    }
    return(
     <button className="post-button" onClick={()=>replyToPost(props.id)}>reply</button>
    )
}

function AddMention(props:{handle:string}){

    const addMention = () =>{
        clearTextbox();
        const postingBox = document.getElementById("posting-box") as HTMLTextAreaElement;
        postingBox?.scrollIntoView({behavior:'smooth', block:"end"})
        postingBox.value += "@"+props.handle
    }
    return(
        <button className="post-button" onClick={addMention}>@</button>
    )
}

export function DeletePost(props:{id:number, postUsername:string}){
    const deletePost = async ()=>{
        if(window.confirm("really delete post?")){
            const res : Response = await fetch(`/delete-post?id=${props.id}`);
            if(res.status != 200) alert('uh oh! Couldn\'t delete for some reason! oh NO! Maybe try again in a sec... If it keeps up, something is borked.')
            else{
                window.location.reload();
            }
        }
    }

    const davidSelection = async ()=>{
        // makes this post a david selection
        if(!window.confirm("make David Selection")) return;
        const res : Response = await fetch(`/make-david-selection?postId=${props.id}`);
        if(res.status != 200) {alert(`Error in making this a david selection...\nstatus: ${res.statusText}`);return;}
        alert("success!");
    }

    return(
        <>
        {(props.postUsername == globalUserName || globalUserName === 'godmode') ? <button className='post-button' onClick={deletePost}>delete</button> : ""}
        {(globalUserName === 'David') ? <button className='post-button' onClick={davidSelection}>⭐DavidSelection</button> : ""}
        </>
    )
}

function ImgViewer(props:{imgUrl:string, active:boolean, callbackDisable:React.Dispatch<React.SetStateAction<boolean>>}){
    // const img = React.useRef<HTMLImageElement>(null)
    // const moveImg = () => {
    //     console.log('moveimg called')
    //     const x_offset = img.current!.offsetLeft;
    //     img.current!.style.left = (x_offset + 100).toString()+"px";
    // }

    return(
        <>
        {props.active &&
            <div className='imgViewerBg' onClick={()=>props.callbackDisable(false)}>
                <img id='imgViewerActiveImg' src={props.imgUrl}/>
            </div>
        }
        </>
    )
}

export function PostOneShot(props: {id: number}) {
    const [postData, setPostData] = React.useState<any | null>(null);
    async function getPostDataById(id: number) {
        const res = await fetch(`/postData?id=${id}`)
        const data = await res.json();
        setPostData(data);
        }
    React.useEffect(()=>{getPostDataById(props.id)}, [])
    
    return(
        <>
        {postData &&
                    <Post avi={postData.avi} 
                    username={postData.username} 
                    content={postData.content} 
                    attachImage={postData.attached_image} 
                    likes={postData.likes} 
                    timestamp={postData.timestamp}
                    id={postData.id}
                    key={postData.id}
                    ncomments={postData.ncomments}
                    reply_to={postData.reply_to}
                    david_selection={postData.david_selection}
                    mask={postData.mask}
                    mask_desc={postData.mask_desc}
                    />
        
        }
        </>
    )
}

function MaskText(props:{text: string, mask_desc:string, toggleShowCallback:()=>void, show: boolean}){

    return(
         <div className="mask-text">
                            {
                                props.show ? 
                                    <div style={{color:'black'}}>
                                        {props.text}
                                    </div> 
                                    :
                                    "▒".repeat(props.text.length)
                            }
                            <br></br>
                            <div className='mask-desc'>
                                <button onClick={props.toggleShowCallback}>{props.show ? `hide` : `show`} text</button> ({props.mask_desc})
                            </div>
                        </div>
    )
}