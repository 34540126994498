import React from "react";


// todo: rly feel like all state should be managed in this thing...
// right now dependencies and asyc between NewPost and MentionAssist

// maybe it can pass an event down to mentionassist ArrowUp
// or last keystroke event?
// basically onKeyUp changes the state of MentionAssist with event.key...
// lastKey prop?

// window.addEventListener("keyup", (event) => {
//       console.log(event);
//   });

interface BufferAction {
    type: "add" | "del" | "overwrite";
    data: string;
}

interface BufferState {
    buffer: string;
}


const startBuffer = {buffer:""}
export default function MentionAssist(props : {usernames: string[], 
                                              lastKey:String,
                                              toggleAssistMode:()=>void,
                                              bubbleUpString:(y:string)=>void }){

    const NUM_CHOICES = 5;
    const [choices, setChoices] = React.useState<string[]>([]);
    const [currIndx, setCurrIndx] = React.useState<number>(0);
    
    const bufferReducer = (state : BufferState, action : BufferAction) => {
        switch(action.type) {
            case 'add':
                setChoices(props.usernames.filter(choice => choice.substring(0,state.buffer.length + 1).toLowerCase() === (state.buffer + action.data).toLowerCase()))
                return {buffer: state.buffer + action.data}
            case 'del':
                setChoices(props.usernames.filter(choice => choice.substring(0,state.buffer.length - 1).toLowerCase() === (state.buffer.slice(0,-1).toLowerCase())))
                return {buffer: state.buffer.slice(0,-1)}
            case 'overwrite':
                return {buffer: ''}
            default:
                throw new Error();
        }
    }
    const [mentionBuffer, dispatchMentionBuffer] = React.useReducer(bufferReducer, startBuffer)
    
    const modifyMentionBuffer = ()=>{
        if(!/[^a-z0-9]/gi.test(props.lastKey.valueOf()) && props.lastKey.length === 1){
            dispatchMentionBuffer({type:"add", data:props.lastKey.valueOf()});
        }
        switch (props.lastKey.valueOf()) {
            case "Backspace":
            case "Process":
                dispatchMentionBuffer({type:'del', data:'x'})
                if(mentionBuffer.buffer.slice(0,-1).length == 0) return;
                break;
            case "ArrowUp":
                setCurrIndx(prev=>((prev+1) % Math.min(choices.length, NUM_CHOICES)));
                break;
            case "ArrowDown":
                setCurrIndx(prev=>Math.abs((prev-1) % Math.min(choices.length, NUM_CHOICES)));
                break;
            case "Enter":
            case "Tab":
                // current problem can't index properly
                const listLen = Math.min(choices.length, NUM_CHOICES);
                const selection = choices.slice(0, listLen)[listLen - currIndx - 1];
                selection && props.bubbleUpString(selection);
                // choices[currIndx] && props.bubbleUpString(choices[(NUM_CHOICES-1) - currIndx]);
                break;
            case "Space":
            case " ":
            case "Control":
            case "Escape":
                props.toggleAssistMode()
                break;
            default:
                break;
        }        
    }
    

    React.useEffect(()=>{
        modifyMentionBuffer();
        
    },[props.lastKey])
    // React.useEffect(()=>{
    //     setChoices(props.usernames)
    // },[])
    return(
        <div id="mention-assist">
                {
                    choices.slice(0,NUM_CHOICES).map((val, i)=><div className={
                        ((Math.min(choices.length, NUM_CHOICES) - 1) - i)===currIndx ? 
                        'mention-assist-item mention-assist-highlight' : 
                        'mention-assist-item'}
                                                  key={i}
                                                  onClick={()=>{dispatchMentionBuffer({type:"overwrite", data:""}); props.bubbleUpString(val);}}>
                                                  @{val}
                                                  </div>)
                }
                <span className="mention-buffer-input"> &gt; {mentionBuffer.buffer}</span>
                
        </div>
    )
}