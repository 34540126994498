import { useEffect, useState } from 'react';
import '../style/style.css';
import { useUser } from '../Main';

import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';

export default function Connections({connectionType}:{connectionType : string}) {

    const {username} = useUser();
    const params = useParams();
    const [connections, setConnections] = useState<{username:string, avi:string}[]>([]);
    useEffect(()=>{
        const getPostData = async () => {
            const res = await fetch(`/get-${connectionType}?id=${params.id}`);
            const data = await res.json();            
            if(!data) return;

            const aviRes = await fetch(`/get-avis-for-users`, {
                    method: "post",
                    headers: { 
                      'Accept': 'application/json',
                      'Content-Type': 'application/json; charset=UTF-8'},
                    body: JSON.stringify({"users":data})
            })
            const aviData = await aviRes.json();
            setConnections(aviData)
        }
        getPostData();
    },[params])

    return(
            <div>
                    {connections.map((obj)=><div key={obj.username} className='mini-user-card'>
                                                <img src={obj.avi} className='mini-user-card-img'/> &nbsp;
                                                <Link to={`/~/u/${obj.username}`} key={obj.username}>{obj.username}</Link>
                                            </div>)}
            </div>
    )
}
