import { Dispatch, SetStateAction } from 'react';
import '../style/style.css';

interface IFactData {
    header : string,
    fval : string
}

interface IFactsProps {
    showEdit : boolean,
    setEdit : Dispatch<SetStateAction<boolean>>,
    facts : Object
    updateCallback : ()=>void
}

export default function EditFacts(props : IFactsProps) {
    let fax = Object.entries(props.facts).map((ffield)=><FactField header={ffield[0]} fval={ffield[1]}/>);
    return(
        <div className="inline-modal">
        <h4>Fun facts about you! Only fill out what you want :-)</h4>
        {fax}
        <button onClick={()=>{props.setEdit(false); props.updateCallback()}}>save</button>
        </div>
    )
}


function FactField(props : IFactData) {
    return(
        <div className="fact-edit-item">
            <span>{props.header}</span> <input type="text" id={props.header} className="custom-fact-field" defaultValue={props.fval}></input>
        </div>
    )
}