/*
    Helper Functions
    ================

*/

import React from "react";

// used to dynamically change YT embed sizes. Calculated once onload
const ytHeight : number = window.innerWidth / window.innerHeight < 1 ? Math.ceil(window.innerWidth * 0.3579) : 210;

// removes JS+HTML from string
const sanitizeHTML = (unsanitized : string) : string => {
    const el : HTMLDivElement = document.createElement("div");
    el.innerText = unsanitized;
    return el.innerHTML;
}

// converts absolute timestamp to relative time string
const humanFriendlyDate = (timeStamp : string) : string => {
    const targetDate : Date = new Date(timeStamp);
    const currentDate : Date = new Date();
    let diff : number = currentDate.getTime() - targetDate.getTime();

    if (diff < 60000)       return (diff/1000).toFixed(2)+"s" // show in seconds
    if (diff < 3600000)     return (diff/60000).toFixed(2)+"m"; // show in minutes
    if (diff < 86400000)    return (diff/3600000).toFixed(2)+"h"; // show in hours
    if (diff < 2592000000)  return (diff/86400000).toFixed(2)+"d"
    if (diff < 31104000000) return (diff/2592000000).toFixed(2)+"m"
    return (diff/31536000000).toFixed(2)+"y";
}


const unixEpoch2HumanString = (unixTime : number) : string => {
    const humanDate : string = new Date(unixTime).toLocaleString();
    return humanDate;
}

function msToTime(duration: number) {
    const milliseconds = Math.floor((duration % 1000) / 100)
    const seconds = Math.floor((duration / 1000) % 60)
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
    const hours = Math.floor((duration / (1000 * 60 * 60)))
  
    const hoursStr = (hours < 10) ? "0" + hours.toString() : hours.toString();
    const minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    const secondsStr = (seconds < 10) ? "0" + seconds : seconds;
  
    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }


// extracts @ mentions... this nasty regex includes emojis
const getMentions = (content : string) : RegExpMatchArray | null => {
    const mentionRe : RegExp = /@[a-zA-Z0-9_<a?:.+?:\d{18}>|\p{Extended_Pictographic}]+/g
    const mentions : RegExpMatchArray | null = content.match(mentionRe);
    return mentions;
}


// may still be useful in the future... async soundcloud embeds from URL
// const getScEmbed = async (scUrl : string) => {
//     const res = await fetch(`https://soundcloud.com/oembed?format=json&url=${scUrl}&iframe=true`);
//     const data : any = res.json();
//     const embedChunks = data.html.split("height=")
//     const embedStr = embedChunks[0]+'allow="autoplay" height="166" '+embedChunks[1]
//     return embedStr;
// }

// injects HTML to post message
// includes hyperlink formatting, emojis, embeds
const textPreprocessor = (content : string, id?:number) : React.ReactNode => {
    // adds @tag links
    let   sanitized : string = sanitizeHTML(content);    
    const puncRe : RegExp = /[!\?\.'":-<>;]+/g
    const mentions : RegExpMatchArray | null = getMentions(sanitized)
    const urlRe : RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=;]*)/g
    const urls : RegExpMatchArray | null = sanitized.match(urlRe);
    const ytReLong : RegExp = /https?:\/\/(www\.)?youtube.com\/watch\?\S+/g
    const ytReShort : RegExp = /https?:\/\/(www\.)?youtu\.be\/\S+/g
    const longYtUrls : RegExpMatchArray | null = sanitized.match(ytReLong)
    const shortYtUrls : RegExpMatchArray | null = sanitized.match(ytReShort)
    // const scReLong : RegExp = /https?:\/\/(www\.)?soundcloud.com\/[a-zA-Z0-9\-\_]+\/\S+/g
    // const scUrlsLong : RegExpMatchArray | null = sanitized.match(scReLong)
    const scEmbedRe : RegExp = /&lt;iframe .* src=\"https:\/\/w\.soundcloud\.com.*&lt;\/[^\s]+&gt;/g
    const scEmbedMatch : RegExpMatchArray | null = sanitized.match(scEmbedRe);
    let trackId : string | null = null;

    // add soundcloud embed 
    scEmbedMatch?.forEach(embedCode=>{
        sanitized = sanitized.replace(embedCode,"");
        const match = embedCode.match(/\/tracks\/\d+&/);
        if(!match) return;
        const foundTrackId = match[0];
        trackId = foundTrackId.substring(8, foundTrackId.length-1);
    })

    // wrap @ mentions in anchor tag
    mentions?.forEach(mention=>{
        // marks where mentions should occ
        const endingPunctuation : RegExpExecArray | null = puncRe.exec(mention);
        let cleanMention = mention;
        endingPunctuation?.forEach(punc => cleanMention = cleanMention.replace(punc, ""));
        sanitized = sanitized.replaceAll(cleanMention, `<a href='/~/u/${cleanMention.substring(1)}' class='mention' >${cleanMention}</a>`)
        // i++;
    });

    // wrap urls in anchor tag
    urls?.forEach(url=>{
        sanitized = sanitized.replaceAll(url, `<a href='${url}' target='_blank'>${url}</a>`)
    })

    // append youtube embed from long url
    longYtUrls?.forEach(ytUrl =>{
        const vidId = ytUrl.split("v=")[1].split("&")[0]
        sanitized += `<br><iframe width="100%" height="${ytHeight}px" src="https://www.youtube.com/embed/${vidId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    })


    // append youtube embed from short url
    shortYtUrls?.forEach(ytUrl =>{
        const vidId = ytUrl.split("be/")[1]
        sanitized += `<br><iframe width="100%" height="${ytHeight}px"src="https://www.youtube.com/embed/${vidId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
    })

    // append soundcloud embed
    // has to go at the end so url parsing doesn't gobble it up
    if(scEmbedMatch && trackId){
        sanitized += `<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${trackId}&color=%23f15fff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>`
    }    

    // emojis
    sanitized = sanitized.replaceAll(":rabbit:", `<img src='https://res.cloudinary.com/dddd4psei/image/upload/v1674164974/rabbit_t0xrxz.gif' style="vertical-align:middle" alt='bunny emoji'>`);
    sanitized = sanitized.replaceAll(":cat:", `<img src='https://res.cloudinary.com/dddd4psei/image/upload/v1674399873/scratchingcat_n8uirh.gif' style="vertical-align:middle" alt='cat emoji'>`);

    return <div className='formatted-content' dangerouslySetInnerHTML={{__html: sanitized}} id='' />;
}




const importAll = (r : any) => {
    let images : any = {};
    r.keys().forEach((item : string) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  };


  function randn_bm(min:number, max:number, skew:number) : number {
    let u = 0, v = 0;
    while(u === 0) u = Math.random() //Converting [0,1) to (0,1)
    while(v === 0) v = Math.random()
    let num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v )
    
    num = num / 10.0 + 0.5 // Translate to 0 -> 1
    if (num > 1 || num < 0) 
      num = randn_bm(min, max, skew) // resample between 0 and 1 if out of range
    
    else{
      num = Math.pow(num, skew) // Skew
      num *= max - min // Stretch to fill range
      num += min // offset to min
    }
    return num
  }

export {sanitizeHTML, humanFriendlyDate, textPreprocessor, getMentions, unixEpoch2HumanString, importAll, msToTime, randn_bm}