
import { useEffect, useRef, useState, useCallback } from 'react';
import nekoNeutral from '../img/neko/neko-neutral.png';

import neko0 from '../img/neko/pat-poses/neko-pat0.png';
import neko1 from '../img/neko/pat-poses/neko-pat1.png';
import neko2 from '../img/neko/pat-poses/neko-pat2.png';
import neko3 from '../img/neko/pat-poses/neko-pat3.png';
import neko4 from '../img/neko/pat-poses/neko-pat4.png';
import neko5 from '../img/neko/pat-poses/neko-pat5.png';
import neko6 from '../img/neko/pat-poses/neko-pat-jpeged-IvanovTv.png';
import neko7 from '../img/neko/pat-poses/neko-pat-meme-IvanovTv.png';
import neko8 from '../img/neko/pat-poses/neko-pat-galaxydude-PHB.gif';
import neko9 from '../img/neko/pat-poses/neko-pat-vintageOvO-SC.png';
import neko10 from '../img/neko/pat-poses/neko-pat-vintageU_U-SC.png';
import neko11 from '../img/neko/pat-poses/neko-pat-devil-666.png';
import neko12 from '../img/neko/pat-poses/neko-pat-cyclopian-666.png';
import neko13 from '../img/neko/pat-poses/neko-pat-cephalocattus-666.png';
import neko14 from '../img/neko/pat-poses/neko-pat-bootsy-666.png';
import neko15 from '../img/neko/pat-poses/neko-pat-maw-666.png';
import neko16 from '../img/neko/pat-poses/neko-pat-cute-necara.png';
import neko17 from '../img/neko/pat-poses/neko-pat-summer-tum.png';
import neko18 from '../img/neko/pat-poses/neko-pat-oneshot-catodio.png';
import neko19 from '../img/neko/pat-poses/neko-pat-long-catodio.png';
import neko20 from '../img/neko/pat-poses/neko-pat-outline-butt.png';
import neko21 from '../img/neko/pat-poses/neko-pat-catodio-Catodio.png';
import neko22 from '../img/neko/pat-poses/neko-pat-close-Cyan.png';
import neko23 from '../img/neko/pat-poses/neko-pat-oblong-Cyan.png';
import neko24 from '../img/neko/pat-poses/neko-static-Cyan.png';
import neko25 from '../img/neko/pat-poses/neko-tailup-catodio.png';
import neko26 from '../img/neko/pat-poses/neko-askew-Cyan.png';
import neko27 from '../img/neko/pat-poses/neko-invert-Cyan.png';
import neko28 from '../img/neko/pat-poses/neko-edatkin.png';

const Neko = ()=>{
    const nekoFrames = [
                        neko0,  neko1,  neko2,  neko3,  neko4,  neko5,  neko6,  neko7,  neko8,  neko9, 
                        neko10, neko11, neko12, neko13, neko14, neko15, neko16, neko17, neko18, neko19,
                        neko20, neko21, neko22, neko23, neko24, neko25, neko26, neko27, neko28
                       ];
    const [nekoFrame, setNekoFrame] = useState<string>(nekoNeutral);
    const [petting, setPetting] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<any>(null);
    const [localPetcount, setLocalPetCount] = useState<number>(0);
    const [baseCatPets, setBaseCatPets] = useState<number>(0);
    
    const animationFrame = useRef<number>(0);

    const petCountStateRef = useRef(localPetcount);
    const basePetsStateRef = useRef(baseCatPets);

    const getBaseCatPets = async ()=>{
        const res = await fetch("/api/get-cat-pets")
        const data = await res.json();
        basePetsStateRef.current = data.pets
        setBaseCatPets(basePetsStateRef.current)
    }
    useEffect(()=>{
        getBaseCatPets();
    },[])
    // let timer = setTimeout(()=>{}, 0);
    const petTheNeko = () => {
        clearTimeout(timerId);
        if(!petting) getBaseCatPets();

        const id = setTimeout(stopPettingTheNeko, 1000);
        setTimerId(id);
        animationFrame.current = (animationFrame.current + 1 + 
                                  Math.floor(Math.random() * (nekoFrames.length - 1))) % 
                                  nekoFrames.length;
        setNekoFrame(nekoFrames[animationFrame.current])
        setPetting(true);
        petCountStateRef.current = petCountStateRef.current + 1;
        setLocalPetCount(petCountStateRef.current);
        }

    const stopPettingTheNeko = () => {
        setPetting(false);
        setNekoFrame(nekoNeutral);
        fetch(`/register-cat-pet-load?pets=${petCountStateRef.current}&lite_pw=ass`)
        .then(res=>getBaseCatPets())
        .then(e=>{
            petCountStateRef.current = 0;
            setLocalPetCount(petCountStateRef.current)
        })
        
    }
    

    return(
        <div>
            <div className={`cat-pet-quantity-indicator ${petting? 'visible' : ''}`}>
                Cat has been pet {baseCatPets + localPetcount} times
            </div>
            <img src={nekoFrame} id="neko" onClick={petTheNeko}/>
        </div>
    )
}

export default Neko;