import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import New from './img/icons/new.gif';
import '../style/style.css';

export default function Search() {
    const [userList, setUserList] = useState<any[]>([]);
    const [fullUserList, setFullUserList] = useState<any[]>([]);
    const searchBar = useRef<HTMLInputElement>(null);
    const getUsers = async ()=>{
        const res = await fetch(`/get-user-list`);
        const data = await res.json();
        setUserList(data);
        setFullUserList(data)
    }
    useEffect(()=>{
        getUsers();
    },[])

    const searchUsers = (searchString : string | undefined) : any[] =>{
        if(!searchString || searchString == "") return fullUserList;
        const newArr : any[] = []
        fullUserList.forEach(user=>{
            if(user.name.toLowerCase().startsWith(searchString.toLowerCase())) newArr.push(user)
        })
      return newArr
    }
    const filterTextList = ()=>{
        console.log(`on change! value:${searchBar.current?.value}`);
        setUserList(searchUsers(searchBar.current?.value))
    }
    return(
        <div id="main-content">
        <div id='content-panel'>
            <div className='mobile-pad'>
            <h1>Search User Directory</h1>
            <p>
            <input ref={searchBar} type="text" id="user-search-text-input" onChange={filterTextList}/>

            </p>
            <div>
                Users:
                <ul>
                    {userList?.map(i => <li key={i.name} className='search-items'><Link to={`/~/u/${i.name}`}>@{i.name}</Link> {(new Date().getTime() - new Date(i.joinDate).getTime()) < 172800000 ? <b className='rainbow'>[NEW!]</b> : ""}</li>)}
                </ul>
            </div>
        </div>
        </div>
        </div>
    )
}
