import React, { useEffect, useState } from 'react';
import '../style/style.css';
import { useUser, globalUserName } from '../Main';
import NewPost, {PostType} from '../components/NewPost';
import {humanFriendlyDate} from '../util/helpers';
import * as Notif from './Notifications';

import  Post, {DeletePost, IPost}  from '../components/Post';
import { textPreprocessor } from '../util/helpers';
import { Link, Outlet, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';

import Back from '../img/icons/DS-back.png';

export default function ReplyThread() {

    // const {username} = useUser();
    const params = useParams();
    const [headerPost, setHeaderPost] = useState<any>();
    const [replies, setReplies] = useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    const scrollToHighlight = (attempts : number)=>{
        if(!searchParams.get("highlight")) return;
        if(attempts > 60) return; // element prob no longer exists
        
        const targetId = searchParams.get("highlight");
        if (targetId === null || targetId.length === 0) return;
        
        const targetEl = document.getElementById(targetId);
        if(!targetEl){
            // target hasn't loaded yet
            setTimeout(()=>scrollToHighlight(attempts+1), 100);
            return;
        }
        // scroll into view
        targetEl?.scrollIntoView({behavior:'smooth', block:"center"})
    }

    useEffect(()=>{
        const getPostData = async () => {
            const res = await fetch(`/postData?id=${params.id}`);
            const data = await res.json();
            setHeaderPost(data);         
        }

        const getReplies = async () => {
            const res = await fetch(`/replies?postId=${params.id}`);
            const data = await res.json()
            setReplies(data.sort(function (a: IPost, b: IPost){return (Date.parse(a.timestamp) - Date.parse(b.timestamp))}))
            scrollToHighlight(0);
        }

        getPostData();
        getReplies();

    },[params])



    

   
    return(
        <div id="main-content">
        <div id="content-panel" >
            <h1><img onClick={()=>navigate(-1)} src={Back}></img> Reply thread {params.id}</h1>
            <div>
                {headerPost?
                    <div>
                    <Post       avi={headerPost.avi}
                                username={headerPost.username}
                                content={headerPost.content}
                                attachImage={headerPost.attached_image}
                                likes={headerPost.likes}
                                timestamp={headerPost.timestamp}
                                id={headerPost.id}
                                ncomments={headerPost.ncomments}
                                reply_to={headerPost.reply_to}
                                disableReply={true}
                                highlight={headerPost.id==searchParams.get("highlight")}
                                mask={headerPost.mask}
                                mask_desc={headerPost.mask_desc}
                    />
                    <div className='mobile-pad'>
                        <NewPost type={PostType.Reply} replyTo={parseInt(params.id!)} />
                    </div>
                
                    <div className="replies">


                        {
                            replies.map(post =>            <Post avi={post.avi}
                                                                 username={post.username}
                                                                 content={post.content}
                                                                 attachImage={post.attached_image}
                                                                 likes={post.likes}
                                                                 timestamp={post.timestamp}
                                                                 id={post.id}
                                                                 ncomments={post.ncomments}
                                                                 reply_to={post.reply_to}
                                                                 key={post.id}
                                                                 highlight={post.id==searchParams.get("highlight")}
                                                                 reply
                                                                 mask={post.mask}
                                                                 mask_desc={post.mask_desc}/>)
                        }
                    </div>
                    </div>
                    
                :
                ""
                }
            </div>
        </div>
        </div>
    )
}
