import { useRef, useState } from 'react';
import { useUser } from '../Main';
import '../style/style.css';
import { sanitizeHTML } from '../util/helpers';


export default function UpdateTicker() {
    const {username} = useUser();

    const tickerText = useRef<HTMLTextAreaElement>(null);
    const MAX_LENGTH = 999
    
    const updateTicker = async () =>{
        const newText = `<b><a href='/~/u/${username}'>@${username}</a>:</b> ` + sanitizeHTML(tickerText.current!.value);
        if(newText.length > MAX_LENGTH) {alert("message body too long!"); return;}
        const res = await fetch("/public-set-ticker-text", {
            method: "post",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({"text":newText})
        });

        if(res.status == 200) alert(`SUCCESS!\nNew text:${newText}`);
        if(res.status == 500) alert("something went wrong!");
        if(res.status == 403) alert("forbidden!");
    }


    
    return(
        <div style={{margin:"50px"}}>
           <h1>Update Ticker</h1>
           <p>
                <textarea ref={tickerText} rows={5} style={{width:"100%"}}></textarea>
           </p>
           <p>
                <button onClick={updateTicker}>update</button>
            </p>
           <h1>Cat Pet Submission Form</h1>
           <p>
            Want to add some new cat poses?
            Submit some pics using <a target="blank" href="https://forms.gle/c4WKjgWsayHmmfye7">this form</a>, and I may add it!
           </p>
        </div>
    )
}
