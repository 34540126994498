import '../style/style.css';
import Connections from './Connections';
import { useParams } from 'react-router-dom';

export default function Likes() {
    const params = useParams();

    return(
        <div id="main-content">
        <div id="content-panel">
            <h1>Post {params.id} likes</h1>
            <Connections connectionType="likes" />
        </div>
        </div>
    )
}

