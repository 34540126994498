/// <reference path='../synth/beepbox.d.ts' />
import '../style/chat.css';
import React, { Ref, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useOnScreen } from '../util/CustomHooks';
import { humanFriendlyDate, unixEpoch2HumanString} from '../util/helpers';
import { IChatMessage, IChatSocketEvent} from '../../../types/websocket-types';
import { globalUserName } from '../Main';


import LoginSound from '../sfx/DS_CHAT_Login.mp3';
import NewMsg1 from '../sfx/DS_NEWMSG1.mp3';
import NewMsg2 from '../sfx/DS_NEWMSG2.mp3';
import NewMsg3 from '../sfx/DS_NEWMSG3.mp3';
import NewMsg4 from '../sfx/DS_NEWMSG4.mp3';
import NewMsg5 from '../sfx/DS_NEWMSG5.mp3';
import NewMsg6 from '../sfx/DS_NEWMSG6.mp3';

// import beepbox from '../lib/beepbox_synth';
// import beepbox from 'beepbox';

// music
// import * as beepbox from '../lib/beepbox_synth.min.js';
// import loginJSON from '../synth/LoginSound';
// import SeededRandomTone, {generateRandomString, genJson} from '../synth/ChatSounds';
/*


TODOS:
    
    
    - New Chat Message popup when a new chat message appears and you have not seen it
        - when you're at the bottom of the scroll.. YOu have seen it!


    - get new values of active members when it decrements
*/


// async function playSound(username : string) {
//     const toHash = username ? username : generateRandomString(10);
//     var song = new beepbox.Song()
//     song.fromJsonObject(genJson(toHash))
//     var synth = new beepbox.Synth(song);
//     synth.loopRepeatCount = 0
//     synth.play();
//     // synth = null
// }

// async function playLogin() {
//     var login = new beepbox.Song()
//     login.fromJsonObject(loginJSON)
//     var loginSynth = new beepbox.Synth(login)
//     loginSynth.loopRepeatCount = 0;
//     loginSynth.play();
//     // loginSynth = null; 
// }



const getHex = (name : string) : string => {
    let R = 0;
    let G = 0;
    let B = 0;
    for (let i = 0; i < name.length; i++) {
        if(i%2==0) R+= name.charCodeAt(i) * 77;
        if(i%5==0) G+= name.charCodeAt(i) * 77;
        if(i%3==0) B+= name.charCodeAt(i) * 77;
    }
    return (R%255).toString(16) + (G%255).toString(16) + (B%255).toString(16);
}


export default function Chatroom() {
    const wsRef = useRef<WebSocket | null>(null);
    const [chatMessages, setChatMessages] = useState<IChatMessage[]>([])
    const [connectedUsers, setConnectedUsers] = useState<number>(0);
    // const latestMsgId = useRef<number>(0);
    // const earliestMsgId = useRef<number>(0);
    const [latestMsgId, setLatestMsgId] = useState<number>(0);
    const [earliestMsgId, setEarliestMsgId] = useState<number>(0);
    const [seenLatestMsg, setSeenLatestMsg] = useState<boolean>(true);

    
    const [loaded, setLoaded] = useState<boolean>(false);

    const newMsgSounds = useRef([NewMsg1, NewMsg2, NewMsg3, NewMsg4, NewMsg5, NewMsg6])
    const audioPlayer = useRef<HTMLAudioElement>(new Audio());
    
    function setSeenLatestMessageCallback(val: boolean) {
        setSeenLatestMsg(val);
    }

    
    const wsHandlers : any = {
        "old-messages-from-server": onOldMessagesFromServer,
        "new-message-from-server": onNewMessageFromServer,
        "connected-users": onConnectedUsersChange
    }


    const sendMsg = (msg: string) => {
        if(!wsRef.current) {
            alert("could not send, no websocket connection!");
            return false;
        }
        if(!globalUserName){
            console.log(`err! No username found`)
            return false;
        }
        if(msg.length > 420){
            alert(`message length too long!\nCurrent length: ${msg.length}\nmax length: ${420}\ndiff: ${msg.length-420}`)
            return false;
        }
        const payload : IChatSocketEvent = {
                                            eventType: "new-chat-message", 
                                            params: {
                                                message: {
                                                username:globalUserName,
                                                colour:getHex(globalUserName),
                                                content:msg,
                                                time:new Date().getTime()
                                                },
                                                msgsToDisplay: 1
                                                }
                                            }
        wsRef.current.send(JSON.stringify(payload));
        
        setTimeout(()=>document.getElementById("EOL")?.scrollIntoView(),500);

        return true;
    }

    function sortMessages(msgs: IChatMessage[]){
        msgs.sort(function(a : IChatMessage, b : IChatMessage) {
            return a.time - b.time;
        });
        return msgs;
    }

    function onNewMessageFromServer (msgs: IChatMessage[]) {
        msgs = sortMessages(msgs)
        newMessageSound();
        setChatMessages(chatMessages => [...chatMessages, ...msgs])
        setSeenLatestMsg(false);
    }

    function onOldMessagesFromServer (content: IChatMessage[]) {
        content = sortMessages(content);
        // earliestMsgId.current = content[0].id;
        setChatMessages(chatMessages => [...content, ...chatMessages]);
    }

    function onConnectedUsersChange (content : any) {
        setConnectedUsers(content);
    }

    function requestInitialMessages () {
        const N = 25;
        if(!wsRef.current){
            alert("could not establish webscoket connection!")
            return;
        }
        const payload : IChatSocketEvent = {
            eventType: "client-wants-last-messages", 
            params: N
            }
        wsRef.current.send(JSON.stringify(payload));
    }

    function requestOlderMessages() {
        const N = 25;
        if(!wsRef.current){
            alert("could not establish webscoket connection!")
            return;
        }
        console.log(`from: ${earliestMsgId - N}`)
        console.log(`to: ${earliestMsgId}`)
        const payload : IChatSocketEvent = {
            eventType: "client-wants-range-messages", 
            params: {from:earliestMsgId - N, to: earliestMsgId - 1}
            }
        wsRef.current.send(JSON.stringify(payload));
    }

    function playLogin(){
        const audio = new Audio(LoginSound);
        audio.play();
    }

    function newMessageSound(){
        audioPlayer.current.src = newMsgSounds.current[Math.floor(Math.random()*newMsgSounds.current.length)]
        audioPlayer.current.load();
        audioPlayer.current.play();

    }

    useEffect(()=>{
        // alert("remember! I am using a relative domain with the websockets (ws/) to see if ti works in prod :)")
        // wsRef.current = new WebSocket('ws://localhost:3001');
        wsRef.current = new WebSocket('wss://david-production.up.railway.app');

        const ws = wsRef.current;
        console.log(ws)

        ws.onerror = (error) => {
            console.log(`🔌websocket error`)
            console.log(error)
        }

        ws.onopen = ()=>{
            playLogin();
            requestInitialMessages();
            setLoaded(true);
        };

        ws.onmessage = (event : any) => {
            const eventObj : IChatSocketEvent = JSON.parse(event.data);                        
            const handleEvent = wsHandlers[eventObj.eventType];
            handleEvent(eventObj.params);
          };
      
          ws.onclose = () => {
            console.log('Disconnected from WebSocket');
          };

          return(()=>ws.close())

    }, [])

    useEffect(()=>{
        // keeps track of chatMessages
        if(chatMessages.length < 1) return;
        setLatestMsgId(chatMessages[chatMessages.length-1].id);
        setEarliestMsgId(chatMessages[0].id);
        // todo...

    }, [chatMessages])

    return(
        <div id="chat-page">
            <h1>Chatroom</h1>
            <div id="chat-status"><span id="activity-indicator">{connectedUsers} active</span></div>
            {   loaded ?
                <ChatContent msgs={chatMessages} 
                             loadMoreCallback={requestOlderMessages}  
                             topId={earliestMsgId} 
                             seenLatestMessage={seenLatestMsg}
                             seenLatestMessageCallback={setSeenLatestMessageCallback}/> :
                <div style={{"textAlign":'center'}}>
                    <ChatsLoadingAnim/>
                    Connecting to chatroom...
                </div>}
            <ChatInput submitCallback={sendMsg}/>
            <div style={{height:"10px"}}/>
        </div>
    )
}


const ChatMessage = React.forwardRef<any, any>( (props: IChatMessage, ref: Ref<HTMLDivElement>) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    return(
        <div className={`chat-line ${expanded && 'open'}`}
             onClick={()=>setExpanded(prev => !prev)}
             id={props.id.toString()}>
            {/* {<div className={"message-meta-tag "+(expanded ? "open" : "closed")} >hiiii {unixEpoch2HumanString(props.time)}<p/><p/></div>} */}
            {/* this type insanity in unixEpoch...String() is needed because it is not treating the number as a number type in the fn despite it being defined as such in IChatMessage  */}
            <div className={`message-meta-tag ${expanded && 'open'}`}>{unixEpoch2HumanString(parseInt(props.time.toString()))}</div>
            <div>
                <span className='chat-user' 
                    style={{color:`#${props.colour}`}}
                    >
                    {props.username}
                </span> &nbsp;
                <span className='text-cursor'>{props.content}</span>
            </div>
        </div>
    )
});

function ChatContent(props: {msgs : IChatMessage[], loadMoreCallback : ()=> void, topId : number, seenLatestMessage : boolean, seenLatestMessageCallback : (s: boolean)=>void}) {
    const endOfList = useRef<HTMLDivElement>(null);
    const topOfList = useRef<HTMLDivElement>(null);
    const chatWindowRef = useRef<HTMLDivElement>(null);
    const lastScrollHeight =  useRef<number>(0);
    // const topMessageRef = useRef<HTMLDivElement>(null);

    const endOfListVisible = useOnScreen(endOfList);
    const [seenNewMessage, setSeenNewMessage] = useState<boolean>(true)
    const topOfListVisible = useOnScreen(topOfList);

    const scrollToBottom = () => {
        endOfList.current?.scrollIntoView({behavior:'smooth'});
    }


    useEffect(()=>{
        props.seenLatestMessageCallback(true);
        // set props.seenLatestMessage to true
        // bubble up basically


        // const chatWin = document.getElementById("chat-content")

        // chatWindowRef.current.addEventListener("scroll", (evt : any) => 
        //     console.log(chatWindowRef.current?.scrollHeight)
        //     // console.log(evt.target.scrollHeight)
        // )
    }, [endOfListVisible])
    
    useEffect(()=>{
        if(!chatWindowRef.current){console.log(`no chat window ref`); return;}
        chatWindowRef.current.scrollTo({left:0, top:chatWindowRef.current.scrollHeight - lastScrollHeight.current})
        lastScrollHeight.current = chatWindowRef.current.scrollHeight
        // if(!chatWindowRef.current) return;
        
    },[props.topId])

    // useLayoutEffect(()=>{
    //     if(!chatWindowRef.current) return;
    //     const scrollY : number = chatWindowRef.current.scrollHeight;
    //     chatWindowRef.current.scrollTo(0, scrollY)
    // },[])

    const scrollToTarget = (targetId : number) => {
        
        console.log(`scroll to target called with target id: ${targetId}`)
        const target : HTMLDivElement = document.getElementById(targetId.toString()) as HTMLDivElement;
        console.log(`target:`)
        console.log(target)
        if(!target) return;
        console.log(`HTML: ${target.innerHTML}`)
        target.scrollIntoView({block: "start", inline: "nearest", behavior:'smooth'});
    }


    return(
        <div id="chat-content-container" style={{flexGrow:1}}>
            <div id="chat-content" ref={chatWindowRef} style={{"textAlign":'left'}}>
                {/* {topOfListVisible && <ChatsLoadingAnim loadingIconLoadMoreCallback={props.chatContentLoadMoreCallback}/>} */}
                <div ref={topOfList}>
                    <button onClick={()=>{props.loadMoreCallback()}}>load more</button>
                </div>
                {props.msgs.map((m : IChatMessage, i : number) => 
                <ChatMessage
                    // ref={m.id == props.topId ? topMessageRef : null}
                    username={m.username}
                    colour={m.colour}
                    content={m.content}
                    time={m.time}
                    id={m.id}
                    key={i}
                    />
                    
                    )}
                <div id="EOL" ref={endOfList} style={{"visibility":"hidden"}}> last msg </div>
            </div>
            {
                !endOfListVisible && !props.seenLatestMessage &&
                <button id="new-msg" onClick={scrollToBottom}>
                    New Message!
                </button>
            }
        </div>
    )
}


function ChatInput(props: {submitCallback:(msg:string)=>boolean}) {
    const textField = useRef<HTMLInputElement>(null);


    useEffect(()=>{
        textField.current?.addEventListener("keyup", e=>inputAccelerator(e))
    })

    const inputAccelerator = (e : KeyboardEvent) => {
        if(e.key == "Enter") {
            getTextContent(e)
            // textField.current?.blur();
        }
    }

    const getTextContent = (e: any) => {
        e.preventDefault();
        if(!textField.current || !textField.current.value) return;
        textField.current.focus();
        const submit = props.submitCallback(textField.current.value);
        if(submit){
            textField.current.value = "";
        }
        return false;
        // textField.current.blur();
    }
    return(
        <div id="chat-input">
            <input ref={textField} className="item" style={{fontSize:"20px"}} type="text"/>
            <div onClick={getTextContent}  style={{display:"flex", justifyContent:"center", alignContent:"center", flexDirection:"column", padding:"5px", border:"1px solid black"}}> submit</div>
        </div>
        // <form id="chat-input" onSubmit={getTextContent}>
        // <input ref={textField} className="item" style={{fontSize:"20px"}} type="text"/>
        // <button type="submit" value={"Submit"} onClick={getTextContent}/>
        // </form>
    )
}


function ChatsLoadingAnim() {
    const [text, setText] = useState<string>("");
    const loading = useRef<boolean>(false);
    const animationFrames = ["-", "\\", "|", "/"];
    useEffect(()=>{
        if(loading.current) return;
        animateText(0);
        console.log("visible!");
    },[])
    const animateText = (i : number) => {
        if (i===animationFrames.length) i = 0;
        setText(animationFrames[i]);
        i += 1;
        setTimeout(()=>animateText(i), 200);
    }
    return(
        <div>
            {text}&nbsp;
        </div>

    )
}


function WriteText() {
    const [text, setText] = useState<string>("");
    
    useEffect(()=>{
        animateText(0);
    },[])
    const animateText = (i : number) => {
        if (i==4) i = 0;
        let buffer = " "
        for (let j = 0; j < i; j++) {
            buffer += "."
        }
        setText(buffer);
        i += 1;
        setTimeout(()=>animateText(i), 200);
    }
    return(
        <div>
            {text}&nbsp;
        </div>

    )
}


// const chatAppState = () => {
//     const[messages, setMessages] = useState<Message[]>([]);

//     // some logic happens...

//     const onNewMessage = async () => {
//         // happens when a user sends a new message
//         const newMsgs : Message[] = await getNewMessages(...)
//         setMessages(newMsgs)
//     }

//     const onLoadPrevMsgs() => {
//         // happens when the user scrolls all the way up
//         // loads the last 10 messages
//         const newMsgs : Message[] = await getOldMessages(numOfDisplayedMsgs, numOfDisplayedMsgs+10)
//         setMessages(newMsgs)

//     }

//     return(
//         <div>
//             messages.map(msg=><div>{msg.content}</div>)
//         </div>
//     )
// }



// const chatAppRef = () => {
//     const chatField = useRef<HTMLDivElement>();
//     const msgs = {} // ...

//     const makeMsg = (msg : Message) : HTMLDivElement =>{
//         // creates a new message div element
//     }

//     const onNewMsgs = (newMsg : Message) => {
//         const msg : HTMLDivElement = makeMsg()        
//         chatField.current?.appendChild(msg)
//     }

//     const onLoadPRevMsgs = (preMsgs : Message[]) =>{
//         const newMsgs : HTMLDivElement[] = [];
//         preMsgs.forEach(msg => {
//             newMsgs.push(makeMsg(msg))
//         })

//         chatField.current?.prepend(...newMsgs)
//     }

//     return(
//         <div ref={chatField}>
//             messages.map(msg=><div>{msg.content}</div>)
//         </div>
//     )
// }