import React, { useEffect } from 'react';
import '../style/style.css';
import { useUser, globalUserName } from '../Main';
import Post from '../components/Post';
import Marquee from 'react-fast-marquee';
import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import internal from 'stream';
import { facts } from '../common/Profile.common';
import { profile } from 'console';
import { IPost } from '../components/Post';
import { NewNotification, NotificationType } from './Notifications';
import { maxHeaderSize } from 'http';
export interface IProfile{
    name : string,
    status: string,
    bio: string,
    facts: typeof facts,
    bootlickers: Array<string>,
    userid: number,
    avi: string,
    following: Array<string>
}

const MtProfile = {
    name : "",
    status: "",
    bio : "",
    facts : facts,
    bootlickers : [],
    userid: -1,
    avi: "",
    following: []
}



const resizeName = (name : string) : number => {
    
    const len = name.length;
    if(window.innerWidth / window.innerHeight > 1 || len < 10) return 30;
    return window.innerWidth * 0.05;
}


export default function Profile() {

    const {username} = useUser();
    const params = useParams();
    const [profileData, setProfileData] = React.useState<IProfile>(MtProfile);
    const [posts, setPosts] = React.useState<any[]>([]);
    const [following, setFollowing] = React.useState(false);
    const [followerCount, setFollowerCount] = React.useState(0);
    const [followingCount, setFollowingCount] = React.useState(0);    
    const [notFound, setNotFound] = React.useState(false);

    useEffect(()=>{
        const fetchProfileData = async () => {
            const res = await fetch(`/profile?username=${params.id}`);
            const data = await res.json();
            
            if(JSON.stringify(data) == '{}') {setNotFound(true); return;} // check if profile not there
            if(!data.avi) data.avi = "/avis/default-avi.jpg";

            setProfileData(data);
            data.bootlickers ? setFollowerCount(data.bootlickers.length) : setFollowerCount(0);
            data.following ? setFollowingCount(data.following.length) : setFollowingCount(0);
            if(data.bootlickers.includes(username)) {
                setFollowing(true)
            }
        }

        const fetchUserPosts = async () => {
            const res = await fetch(`/userPosts?username=${params.id}`);
            const data = await res.json();
            setPosts(data);
        }


        fetchProfileData()
        .catch(console.error);

        fetchUserPosts()
        .catch(console.error);
    
    },[username])    

    const follow = () =>{
        
        fetch("/follow-user", {
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({username:username, toFollow:params.id})
        })
        NewNotification(username!, 
                        params.id!,
                        "",
                        '',
                        NotificationType.Follow);

        setFollowerCount(followerCount + 1);
        setFollowing(true);
    }

    const unfollow = () => {
        fetch("/unfollow-user", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({username:username, toUnfollow:params.id})
        })
        setFollowerCount(followerCount - 1);
        setFollowing(false);
    }
   
    return(
        <div id="main-content">
        <div id="content-panel" >
            {/* <div className={notFound ? "hide-me" : ""}> */}
            <div className='mobile-pad'>
                <div id="profile-header">
                    <img src={profileData.avi || '/img/loading-hourglass.gif'} id="profile-avi"></img>
                    <div className='profile-header-r'>
                        <span className='profile-title' style={{fontSize:resizeName(profileData.name)}}>{profileData.name}</span>
                        {/* <h1>{profileData.name}</h1> */}

                        <Marquee gradient={false}>{profileData.status}</Marquee>
                        <br></br>
                        <div className="follower-panel" >
                            {
                                following ?
                                    <button className="unfollow" onClick={unfollow}>unbootlick</button> 
                                    :
                                    <button onClick={follow}>bootlick</button> 
                            }
                            &nbsp;bootlickers:
                            <Link to={`/~/bootlickers/${params.id}`}>{followerCount}</Link> 
                            <br/>
                            bootlicking: 
                            <Link to={`/~/u-following/${params.id}`}>{profileData.following? profileData.following.length : 0}</Link>
                        </div>
                    </div>
                </div>
                <hr/>
                <div>

                    <h3>About Me</h3>

                    {/* bio */}
                    <div>
                            {profileData.bio}
                    </div>
                </div>
                <div>
                    
                <div>
                    <ul id="facts">
                        {
                        profileData.facts ? 
                        Object.entries(profileData.facts)
                        .filter(([_,v]) => v != "")
                        .map(e => <li key={e[0]}><span className="profile-fact">{e[0]}</span> {e[1].toString()}</li>)
                        :
                        ""
                    }
                    </ul>
                </div>
                    
                </div>
                </div>
                <hr/>
                

                
                <div id="profile-feed">
                    {posts.map((p)=><Post 
                                        key={p.id}
                                        avi={p.avi}
                                        username={p.username}
                                        content={p.content}
                                        attachImage={p.attached_image}
                                        likes={p.likes}
                                        timestamp={p.timestamp}
                                        id={p.id} 
                                        ncomments={p.ncomments}
                                        reply_to={p.reply_to}
                                        david_selection={p.david_selection}
                                        mask={p.mask}
                                        mask_desc={p.mask_desc}
                                        />)}
                                        
                </div>
            {/* </div> */}
            <div className={notFound? "" : "hide-me"}>
                <h1>USER NOT FOUND</h1>
            </div>
            
        </div>
        </div>
    )
}
