import '../style/style.css';
import { useParams} from 'react-router-dom';


export default function Bulletin() {

    const params = useParams();
    return(
        <div id="main-content">
        <div id="content-panel" >
            <h1>{params.msg}</h1>            

        </div>
        </div>
    )
}
